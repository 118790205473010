// import { createI18n } from 'vue-i18n'
// import en from './locales/en.json'
// import de from './locales/de.json'
// import axios from "axios";

// function loadLocaleMessages() {
//   const locales = [{ en: en }, { de: de }]
//   const messages = {}
//   locales.forEach(lang => {
//     const key = Object.keys(lang)
//     messages[key] = lang[key] 
//   })
//   return messages
// }
// var language = 'de';
// axios
//   .get(`${process.env.VUE_APP_BASE_URL}/user/me`, {
//     withCredentials: true,
//   })
//   .then((res) => {
//     if (res.data && res.data.payload && res.data.payload.user) {
//       console.log(res.data.payload.user,'rahul')
//       const user = res.data.payload.user;
//       language = user.language;
//     }
//   });
//   console.log(language)
// export default createI18n({
//   locale: language,
//   fallbackLocale: language,
//   messages: loadLocaleMessages()
// })

import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import de from './locales/de.json';
import axios from "axios";

function loadLocaleMessages() {
  const locales = [{ en: en }, { de: de }]
  const messages = {}
  locales.forEach(lang => {
    const key = Object.keys(lang)
    messages[key] = lang[key] 
  })
  return messages
}
async function setupI18n() {
  let userLanguage = 'de'; // Default fallback language

  // const router = useRouter();https://webglobic.dailytime.io/sign_in
  // var sign_in_route = "https://webglobic.dailytime.io/sign-in";
  var sign_in_route = "/sign-in";
  const currentRouteName = window.location.href;
  console.log(currentRouteName);
  if(localStorage.isUserLoggedIn == 'yes' && !currentRouteName.includes(sign_in_route))
  {
    // await axios.get(`${process.env.VUE_APP_BASE_URL}/user/me`, {
    //   withCredentials: true,
    // });

    // if (this.settingsData.password) {
    //   body.password = this.settingsData.password;
    // }
   await axios
      .get(`${process.env.VUE_APP_BASE_URL}/user/me`, {
        withCredentials: true,
      })
      .then((response) => {
              if (response.data && response.data.payload && response.data.payload.user) {
        const user = response.data.payload.user;
        userLanguage = user.language;
      }
        }).catch(() => {
          userLanguage = 'de';
        });


    // if(!response.error){
    //   if (response.data && response.data.payload && response.data.payload.user) {
    //     const user = response.data.payload.user;
    //     userLanguage = user.language;
    //   }
    // }
  }
  

  return createI18n({
    locale: userLanguage,
    fallbackLocale: userLanguage,
    messages: loadLocaleMessages(),
  });
}

export default setupI18n;