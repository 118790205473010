<template>
  <div>
    <label class="form-control-label" v-if="hasLabel"><slot /> </label>
    <Datepicker
      v-model="date"
      :range="range"
      :multiCalendars="multiCalenders"
      :textInput="textInput"
      :multiDates="multiDates"
      format="dd-MM-yyyy"
      previewFormat="dd-MM-yyyy"
      :placeholder="placeholder"
      @closed="changeDate"
      @cleared="cleared"
    />
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, onMounted } from "vue";
export default {
  name: "VsudDatePicker",
  data() {
    return {
      today: new Date(),
    };
  },
  props: {
    range: {
      type: Boolean,
    },
    multiCalenders: {
      type: Boolean,
    },
    textInput: {
      type: Boolean,
    },
    multiDates: {
      type: Boolean,
    },
    startDate: {
      type: Date,
      default: () => new Date(),
    },
    endDate: {
      type: Date,
      default: () => new Date(new Date().setDate(new Date().getDate() + 7)),
    },
    hasLabel: {
      default: true,
    },
    placeholder: {
      default: "",
    },
  },
  components: {
    Datepicker,
  },
  setup(props) {
    const date = ref();

    onMounted(() => {
      const sDate = props.startDate;
      const eDate = props.endDate;
      date.value = [sDate, eDate];
    });

    return {
      date,
    };
  },
  methods: {
    changeDate() {
      this.$emit("date-range", JSON.stringify(this.date));
    },
    cleared() {
      this.$emit('cleared')
    }
  },
};
</script>

<style scoped>
</style>