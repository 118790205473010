<template>
  <aside
    class="
      my-3
      overflow-auto
      border-0
      sidenav
      navbar navbar-vertical navbar-expand-xs
      border-radius-xl
    "
    :class="isRTL ? 'me-3 rotate-caret' : 'ms-3'"
    id="sidenav-main"
  >
    <div class="sidenav-header">
      <i
        class="
          top-0
          p-3
          cursor-pointer
          fas
          fa-times
          text-secondary
          opacity-5
          position-absolute
          end-0
          d-none d-xl-none
        "
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      <span class="m-0 navbar-brand" href="/">
        <!-- <span class="ms-1 font-weight-bold">DailyTime</span> -->
        <div class="text-start d-flex">
          <svg
            width="31"
            height="22"
            viewBox="0 0 31 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.93751 0.132324H23.4543L13.5167 14.8769H0L9.93751 0.132324Z"
              fill="#FF814F"
            />
            <path
              d="M10.5297 16.2436L6.68066 21.9546H20.8386L30.7761 7.20996H20.3267L14.2382 16.2436H10.5297Z"
              fill="#F9CEBD"
            />
          </svg>

          <h5 class="mb-2 mx-md-2 fw-bolder">DailyTime</h5>
        </div>
      </span>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :cardBg="customClass" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";

import logo from "@/assets/img/logo-ct.png";
import { mapState } from "vuex";

export default {
  name: "index",
  components: {
    SidenavList,
  },
  data(){
    return logo
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["isRTL", "compName"]),
  },
};
</script>