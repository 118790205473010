<template>
  <div class="position-fixed cs_end p-3 opacity-100" style="z-index: 11">
    <div id="liveToast" class="toast bg-warning opacity=100" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <strong class="me-auto">{{ $t('error') }}</strong>
        <button type="button" class="btn-close bg-primary" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        {{ errorMessage }}
      </div>
    </div>
  </div>

  <div class="container mt-5 ">
    <div class="row flex-nowrap">     
      <div class="col-3 project-name-cs">
        {{ userName }}
      </div>

    </div>
  </div>

  <div class="container mt-4 mx-2 mx-auto">
    <div class="row">
      <div class="col-3 p-3 card cs_card mb-3" style="background: #fff; border-radius: 16px;">
        <div class="container">
          <div class="col-12">
            <div>{{ $t('total_projects') }}</div>
            <div class="divider mt-2"></div>
          </div>
          <div class="col-12 mt-2 mb-2">
            <div class="row">
              <div class="col-9">{{ $t('assigned') }}</div>
              <div class="col-3">
                <div>
                  {{ this.projects.totalProjects ? this.projects.totalProjects : 0 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 p-3 card  mb-3 cs_card" style="background: #fff; border-radius: 16px;">
        <div class="container">
          <div class="col-12">
            <div>{{ $t('total_hours') }}</div>
            <div class="divider mt-2"></div>
          </div>
          <div class="col-12 mt-2 mb-2">
            <div class="row">
              <div class="col-9">{{ $t('booked') }}</div>
              <div class="col-3">
                <div>
                  {{ this.projects.bookedHours ? this.projects.bookedHours : 0 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 p-3 card  mb-3 cs_card" style="background: #fff; border-radius: 16px;">
        <div class="container">
          <div class="col-12">
            <div>{{ $t('current_week') }}</div>
            <div class="divider mt-2"></div>
          </div>
          <div class="col-12 mt-2 mb-2">
            <div class="row">
              <div class="col-9">{{ $t('booking') }}</div>
              <div class="col-3">
                <div>
                  {{ this.projects.currentWeekHrs ? this.projects.currentWeekHrs : 0 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3 p-3 card  mb-3 cs_card" style="background: #fff; border-radius: 16px;">
        <div class="container">
          <div class="col-12">
            <div>{{ $t('most_worked_project') }}</div>
            <div class="divider mt-2"></div>
          </div>
          <div class="col-12 mt-2 mb-2">
            <div class="row">
              <div class="col-12">
                <div>
                  {{ this.projects.mostWorkedProject ? this.projects.mostWorkedProject : '' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <!--Here-->
      <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">Confirmation</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {{ $t('delete_confirmation') }}?
              <br />
              {{ $t('deleted_undone') }}!
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                {{ $t('no') }}
              </button>
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="deleteUser(toDelete)">
                {{ $t('yes') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--Close-->

      <!-- Tabs to switch -->

      <div class="container ms-n3 mt-2 mb-2 p-2">
        <div class="row flex-nowrap m-2">
          <div class="col-5 tab_container">
            <div class="tab" @click="setCurrentTab('Projects')">{{ $t('projects') }}</div>
            <div class="tab_divider" v-if="activeTab === 'Projects'"></div>
          </div>
          <div v-if="activeTab === 'Projects'" style="margin-left: 70%;" class="col-md-2 text-end">
            <vsud-button @click="goToNewSheet">
              {{ $t('download_report') }}
            </vsud-button>
          </div>
        </div>
      </div>

      <!-- End of tabs to switch -->
      

      <!-- Projects table  -->
      <div class="mx-auto col-12 flex-column" v-if="activeTab === 'Projects'">
        <div class="col-3" style="float:right">
          <VsudDatePicker :textInput="true" :range="true" @date-range="dateRange" :has-label="false" :start-date="''"
            :end-date="''" format="yyyy-MM-DD" @cleared="dateCleared" :placeholder="$t('all_time_period')"></VsudDatePicker>
        </div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="list-tab" data-bs-toggle="tab" data-bs-target="#list" type="button"
              role="tab" aria-controls="home" aria-selected="true">
              <img src="../assets/img/list.png" class="list" width="15" height="15">
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="graph-tab" data-bs-toggle="tab" data-bs-target="#graph" type="button" role="tab"
              aria-controls="profile" aria-selected="false">
              <img src="../assets/img/bar-chart.png" class="bar-chart" width="15" height="15">
            </button>
          </li>
        </ul>

        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="list" role="tabpanel" aria-labelledby="list-tab">
            <div class="mt-4 card card-plain">
              <div class="card mb-4">
                <div class="card-header pb-0 p-3">
                  <div class="row">
                    <div class="col-6 d-flex align-items-center">
                      <h6 class="mb-0">{{ $t('projects_overview') }}</h6>
                    </div>
                    <div class="col-6 text-end" v-if="false">
                      <vsud-button @click="goToTimesheet">
                        <i class="fas fa-plus me-2"></i>
                        {{ $t('add_new_record') }}
                      </vsud-button>
                    </div>
                  </div>
                </div>
                <div class="card-body px-0 pt-0 pb-2 mt-2">
                  <div class="table-responsive p-0">
                    <table class="table align-items-center justify-content-center mb-0">
                      <thead>
                        <tr>
                          <th width="50%">{{ $t('project_name') }}</th>
                          
                          <th class="text-center" width="25%">{{ $t('booked_hours') }}</th>
                          <th class="text-center" width="25%">{{ $t('booking_percentage') }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="projects.projectList ? (projects.projectList.length === 0 ? true : false) : true">
                        <tr>
                          <td></td>
                          <td class="text-center">
                            <div style="text-align: end;">{{ $t('no_data_found') }}</div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                      <tbody v-if="projects.projectList ? (projects.projectList.length === 0 ? false : true) : false">
<template  v-for="(project, index) in projects.projectList" :key="index">                        
<tr  @click="toggle(index)" class="cursor-pointer" :class="{ opened: opened.includes(index) }">
                          <td class="text-center">
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-sm">
                                  {{ `${project.projectName}` }}
                                </h6>
                              </div>
                            </div>
                          </td>
                         
                          <td class="text-center">
                            {{ project.bookedHours }}
                          </td>
                          <td class="text-center">
                            {{ `${project.percentage}%` }}
<button class="drop-btn btn p-0 float-end mb-0" type="button"
               aria-selected="false">
              <img src="../assets/img/circle-chevron-down-solid.svg" class="dropdown" width="20" height="30">
            </button>
                          </td>
                        </tr>
<tr v-if="opened.includes(index)">
<td  colspan=3>
<table class="table align-items-center justify-content-center mb-0 font-small">

<tbody>
<tr v-for="(activities, index1) in project.activities" :key="index1">
<td class="text-left" width="50%">
                            {{ activities.activityName }}
                          </td>
<td class="text-center" width="25%">
                            {{ activities.bookedHours }}
                          </td>
<td class="text-center" width="25%">
                            {{ activities.percentage }}%
                          </td>
</tr>
</tbody>
</table></td></tr>
</template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="graph" role="tabpanel" aria-labelledby="graph-tab">
            <chart :ChartDashboard="projects.projectList" :key="componentKey" />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import VsudButton from "@/components/VsudButton.vue";
import { mapMutations } from "vuex";
// import AddTimesheet from "./AddTimesheet.vue";
import Chart from './components/AdminDashboard/ChartAdminDashboard.vue';
import VsudDatePicker from "../components/VsudDatePicker.vue";

export default {
  name: "projects-table",
  components: {
    VsudButton,
    VsudDatePicker,
    Chart
    // AddTimesheet
  },
  data() {
    return {
      toDelete: "",
      activeTab: "Projects",
      projects: {
        "totalProjects": 0,
        "bookedHours": 0,
        "currentWeekHrs": 0,
        "mostWorkedProject": "",
        "projectList": [],
        "timeSheet": [],
      },
      startDate: "",
      endDate: "",
      componentKey: 0,
      userName:'',
opened:[]
    };
  },

  beforeMount() {
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
  },
  mounted() {
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
  

    this.fetchData();
  },

  created() {
    var _isUserLoggedIn = localStorage.isUserLoggedIn;
    if (_isUserLoggedIn === undefined) {
      localStorage.isUserLoggedIn = "no";
      _isUserLoggedIn = localStorage.isUserLoggedIn;
    }
    if (_isUserLoggedIn === "no") {
      this.$router.push({
        path: `/sign-in`,
      });
      return;
    }
  },

  methods: {
    ...mapMutations([
 

    ]),
    showOptions() { },
    fetchData() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/user/projects/summary?startDate=${this.startDate}&endDate=${this.endDate}`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data) {
            if (res.data && res.data.payload && res.data.payload.projects) {
              this.projects = res.data.payload.projects;
              this.userName = res.data.payload.projects.userFullName;
              this.componentKey += 1;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteUser(toDelete) {
      const toastLiveExample = document.getElementById("liveToast");
      const toast = new window.bootstrap.Toast(toastLiveExample);
      axios
        .delete(`${process.env.VUE_APP_BASE_URL}/timesheet/` + toDelete, {
          withCredentials: true,
        })
        .then(() => {
          this.errorMessage = this.$t('record_deleted_successfully');
          toast.show();
          this.fetchData();
        })
        .catch((error) => {
          this.errorMessage = this.$t('error_deleting_the_record');
          toast.show();
          console.log(error);
        });
    },

    toDel(startDate) {
      this.toDelete = startDate;
    },

    setCurrentTab(currentTab) {
      this.activeTab = currentTab
    },

    getCurrentWeek(startTime) {
      const startDay = new Date(startTime);
      const newStartDayText = startDay.getDate() + "/" + (startDay.getMonth() + 1) + "/" + startDay.getFullYear();
      const endDay = new Date(startDay.setDate(startDay.getDate() + 6));
      const newEndDayText = endDay.getDate() + "/" + (endDay.getMonth() + 1) + "/" + endDay.getFullYear();
      const week = `${newStartDayText} - ${newEndDayText}`;
      return week;
    },
    goToTimesheet() {
      this.$store.state.showSidenav = false;
      this.$router.push({
        path: `/timesheet`,
      });
    },
    goToWeek(id) {
      console.log(id)
      this.$store.state.showSidenav = false;
      this.$router.push({
        path: `/users/timesheet/${id}`,
      });
    },
    dateRange(payload) {
      const parsedValue = JSON.parse(payload);
      if (parsedValue[0] && parsedValue[1]) {
        const parsedValue = JSON.parse(payload);
        var start = new Date(parsedValue[0]);
        var end = new Date(parsedValue[1]);
        this.startDate = "" + start.getFullYear() + '-' + ((start.getMonth() + 1) > 9 ? '' : '0') + (start.getMonth() + 1) + '-' + (start.getDate() > 9 ? '' : '0') + start.getDate();
        this.endDate = "" + end.getFullYear() + '-' + ((end.getMonth() + 1) > 9 ? '' : '0') + (end.getMonth() + 1) + '-' + (end.getDate() > 9 ? '' : '0') + end.getDate();
        console.log(this.startDate, this.endDate)
        this.fetchData();
      }
    },
    dateCleared() {
      this.startDate = "";
      this.endDate = "";
      this.fetchData();
    },
    close_resource() {
      window.history.back();
    },

 toggle(id) {
    	const index = this.opened.indexOf(id);
      if (index > -1) {
      	this.opened.splice(index, 1)
      } else {
      	this.opened.push(id)
      }
    },
    goToNewSheet() {
      var report_time = 'All';
      const today_date = new Date();

      let day = today_date.getDate();
      let month = today_date.getMonth() + 1;
      let year = today_date.getFullYear();
      let hours = (today_date.getHours() < 10 ? '0' : '') + today_date.getHours();
      let mins = (today_date.getMinutes() < 10 ? '0' : '') + today_date.getMinutes();
      let secs = (today_date.getSeconds() < 10 ? '0' : '') + today_date.getSeconds();
      let today = day + '-' + month + '-' + year + 'T' + hours + 'H-' + mins + 'm-' + secs + 's';
      if (this.activeTab == 'Projects') {
        if (this.startDate != '') {
          var date = new Date(this.startDate);
          var result = "" + (date.getDate() > 9 ? '' : '0') + date.getDate() + '-' + ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + '-' + date.getFullYear();
          var date1 = new Date(this.endDate);
          var result1 = "" + (date1.getDate() > 9 ? '' : '0') + date1.getDate() + '-' + ((date1.getMonth() + 1) > 9 ? '' : '0') + (date1.getMonth() + 1) + '-' + date1.getFullYear();
          report_time = result + ' To ' + result1;
        }
        this.csvContent = "data:text/csv;charset=utf-8,Employee Name:,"+this.userName+",\nReport Period:," + report_time + ",\n\nProject,Booked Hours,Booking Percentage\n";
        this.projects.projectList.forEach((record) => {
          this.csvContent += `${record.projectName},${record.bookedHours},${record.percentage}\n`;
        });
      }
      // else {
      //   this.csvContent = "data:text/csv;charset=utf-8,Week,Mon,Tue,Wed,Thur,Fri,Sat,Sun,Total,Status\n";
      //   this.projects.timeSheet.forEach((project) => {
      //     this.csvContent += `${this.getCurrentWeek(project.startDate)},${project.weekCount ? project.weekCount[0] : 0},${project.weekCount ? project.weekCount[1] : 0},${project.weekCount ? project.weekCount[2] : 0},${project.weekCount ? project.weekCount[3] : 0},${project.weekCount ? project.weekCount[4] : 0},${project.weekCount ? project.weekCount[5] : 0},${project.weekCount ? project.weekCount[6] : 0},${project.weekCount ? project.weekCount.reduce((a, b) => a + b, 0) : 0},${project.isDraft ? "Draft" : "Submitted"}\n`;
      //   });
      // }

      var csv_name = 'employee-reports-' + today + '.csv';
      const encodedUri = encodeURI(this.csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", csv_name);
      document.body.appendChild(link);

      link.click();
    },
  },
};
</script>

<style scoped>
.tab_container {
  width: 120px !important;
}

.tab {
  position: relative;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.tab_divider {
  height: 2px;
  background: #ff814f;
}

.divider {
  height: 1px;
  background: #E8EDF1;
}

.table-row {
  cursor: pointer;
}

.ts-status-draft div {
  width: 100%;
  padding: 5px;
  background-color: rgba(255, 129, 79, 0.2);
  border-radius: 30px;
  color: rgba(255, 129, 79, 1);
}

.ts-status-submitted div {
  width: 100%;
  padding: 5px;
  background-color: rgba(229, 246, 239, 1);
  border-radius: 30px;
  color: rgba(56, 203, 137, 1);
}

.cs_img {
  height: 16px;
  width: 16px;
}

.cs_end {
  right: 0;
  top: 0;
}

.cs_card {
  width: 300px !important;
  margin-right: 20px !important;
}

.back_arrow_cs {
  cursor: pointer;
  height: 16px !important;
  width: 16px !important;
}

.project-name-cs {
  font-size: 18px;
  font-weight: 600;
  /* color:#ff814f; */
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.opened button,.opened:hover button{
transform:rotate(-180deg);
}
.cursor-pointer
{
cursor:pointer;
}
.drop-btn{
position:relative;
right:9%;
}
.drop-btn,.drop-btn:hover,.drop-btn:active,.drop-btn:focus,.drop-btn:active, .drop-btn:active:focus, .drop-btn:active:hover{
box-shadow:none !important;
}
.font-small{
font-size:small;
}
</style>