<template>
  <main class="mt-0 main-content main-content-bg">
    <div class="position-fixed top-0 end-0 p-3 opacity-100" style="z-index: 11">
      <div
        id="liveToast"
        class="toast bg-warning opacity=100"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="toast-header">
          <strong class="me-auto">{{ $t('error') }}</strong>
          <button
            type="button"
            class="btn-close bg-primary"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
        <div class="toast-body">
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <section>
      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-6 d-flex flex-column vh-100 bg-white">
              <div class="mt-5 mx-5 card card-plain">
                <div class="pb-0 card-header text-start d-flex"><svg width="31" height="22" viewBox="0 0 31 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.93751 0.132324H23.4543L13.5167 14.8769H0L9.93751 0.132324Z" fill="#FF814F"/>
<path d="M10.5297 16.2436L6.68066 21.9546H20.8386L30.7761 7.20996H20.3267L14.2382 16.2436H10.5297Z" fill="#F9CEBD"/>
</svg>

                  <h6 class="mb-0 mx-2">
                    DailyTime
                  </h6>
                </div>
                <div class="mt-5 pb-0 card-header text-start">
                  <h3 class="mb-0">
                    {{ $t('reset_password') }}
                  </h3>
                </div>
                <div class="card-body">
                  <label>OTP</label>
                  <div class="form-group">
                    <div class="email-input-holder">
                      <input
                        id="otp"
                        :type="otpType"
                        class="form-control form-control-default email-input password-input"
                        name="otp"
                        v-model="otp"
                      /><svg class="email-icon" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9692 6.3999H2.24615C1.55792 6.3999 1 6.93716 1 7.5999V11.7999C1 12.4626 1.55792 12.9999 2.24615 12.9999H10.9692C11.6575 12.9999 12.2154 12.4626 12.2154 11.7999V7.5999C12.2154 6.93716 11.6575 6.3999 10.9692 6.3999Z" stroke="#72758A" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.49231 6.4V4C3.49231 3.20435 3.82054 2.44129 4.40478 1.87868C4.98903 1.31607 5.78144 1 6.60769 1C7.43395 1 8.22636 1.31607 8.8106 1.87868C9.39485 2.44129 9.72308 3.20435 9.72308 4V6.4" stroke="#72758A" stroke-linecap="round" stroke-linejoin="round"/>
</svg><svg :onclick="toggleOtpType" class="password-icon" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7.47472C1 7.47472 4 1 9.25 1C14.5 1 17.5 7.47472 17.5 7.47472C17.5 7.47472 14.5 13.9494 9.25 13.9494C4 13.9494 1 7.47472 1 7.47472Z" stroke="#72758A" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 10.7122C10.6569 10.7122 12 9.26273 12 7.47479C12 5.68684 10.6569 4.23743 9 4.23743C7.34315 4.23743 6 5.68684 6 7.47479C6 9.26273 7.34315 10.7122 9 10.7122Z" stroke="#72758A" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                    </div>
                  </div>
                  <label>{{ $t('password') }}</label>
                  <div class="form-group">
                    <div class="email-input-holder">
                      <input
                        id="password"
                        :type="passwordType"
                        class="form-control form-control-default email-input password-input"
                        name="password"
                        v-model="password"
                      /><svg class="email-icon" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9692 6.3999H2.24615C1.55792 6.3999 1 6.93716 1 7.5999V11.7999C1 12.4626 1.55792 12.9999 2.24615 12.9999H10.9692C11.6575 12.9999 12.2154 12.4626 12.2154 11.7999V7.5999C12.2154 6.93716 11.6575 6.3999 10.9692 6.3999Z" stroke="#72758A" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.49231 6.4V4C3.49231 3.20435 3.82054 2.44129 4.40478 1.87868C4.98903 1.31607 5.78144 1 6.60769 1C7.43395 1 8.22636 1.31607 8.8106 1.87868C9.39485 2.44129 9.72308 3.20435 9.72308 4V6.4" stroke="#72758A" stroke-linecap="round" stroke-linejoin="round"/>
</svg><svg :onclick="togglePasswordType" class="password-icon" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7.47472C1 7.47472 4 1 9.25 1C14.5 1 17.5 7.47472 17.5 7.47472C17.5 7.47472 14.5 13.9494 9.25 13.9494C4 13.9494 1 7.47472 1 7.47472Z" stroke="#72758A" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 10.7122C10.6569 10.7122 12 9.26273 12 7.47479C12 5.68684 10.6569 4.23743 9 4.23743C7.34315 4.23743 6 5.68684 6 7.47479C6 9.26273 7.34315 10.7122 9 10.7122Z" stroke="#72758A" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                    </div>
                  </div>
                  <label>{{ $t('confirm_password') }}</label>
                  <div class="form-group">
                    <div class="email-input-holder">
                      <input
                        id="confirmPassword"
                        :type="confirmType"
                        class="form-control form-control-default email-input password-input"
                        name="confirmPassword"
                        v-model="confirmPassword"
                      /><svg class="email-icon" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9692 6.3999H2.24615C1.55792 6.3999 1 6.93716 1 7.5999V11.7999C1 12.4626 1.55792 12.9999 2.24615 12.9999H10.9692C11.6575 12.9999 12.2154 12.4626 12.2154 11.7999V7.5999C12.2154 6.93716 11.6575 6.3999 10.9692 6.3999Z" stroke="#72758A" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.49231 6.4V4C3.49231 3.20435 3.82054 2.44129 4.40478 1.87868C4.98903 1.31607 5.78144 1 6.60769 1C7.43395 1 8.22636 1.31607 8.8106 1.87868C9.39485 2.44129 9.72308 3.20435 9.72308 4V6.4" stroke="#72758A" stroke-linecap="round" stroke-linejoin="round"/>
</svg><svg :onclick="toggleConfirmType" class="password-icon" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7.47472C1 7.47472 4 1 9.25 1C14.5 1 17.5 7.47472 17.5 7.47472C17.5 7.47472 14.5 13.9494 9.25 13.9494C4 13.9494 1 7.47472 1 7.47472Z" stroke="#72758A" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 10.7122C10.6569 10.7122 12 9.26273 12 7.47479C12 5.68684 10.6569 4.23743 9 4.23743C7.34315 4.23743 6 5.68684 6 7.47479C6 9.26273 7.34315 10.7122 9 10.7122Z" stroke="#72758A" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                    </div>
                  </div>
                  <div class="text-center">
                    <vsud-button
                      v-on:click="onSignin"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="success"
                      full-width
                      >{{ $t('confirm') }}
                    </vsud-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-7 col-xl-8">
              <div class="pt-6 pb-2 px-5 card card-plain vh-100 justify-content-around">
                <div class="pb-0 text-start">
                  <h3 class="mb-0 text-center">
                    {{ $t('dailytime_dashboard') }}
                  </h3>
                </div>
                <div class="pb-0 text-start">
                  <p class="mt-2 px-6 text-center">
                    {{ $t('easy_to_use_platform') }}
                  </p>
                </div>
                <div></div>
                <div class="text-center img-wrapper">
            <img
            class="main-img"
              src="@/assets/img/login-bg.png"
              alt="login-bg"
            />
            <!-- <img
            class="status-img"
              src="@/assets/img/login-status.png"
              alt="login-status"
            /> -->
          </div>
            <div class="d-flex flex-row justify-content-between footer">
              <div>&copy; {{ $t('all_rights_reserved_dailyTime') }}</div>
            <div>{{ $t('developed_by_webGlobic') }} <a href="https://www.webglobic.de/" target="_blank" class="webglobic">WebGlobic Technologies GmbH</a></div>
            </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "SignIn",
  components: {
    VsudButton,
  },
  data() {
    return {
      password: "",
      otp: "",
      confirmPassword: "",
      errorMessage: "",
      passwordType: 'password',
      otpType: 'password',
      confirmType: 'password',
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations([
      "toggleEveryDisplay",
      "toggleHideConfig",
      "setCompanyName",
    ]),
    togglePasswordType() {
      if(this.passwordType === 'password') {
        this.passwordType = 'text'
      } else {
        this.passwordType = 'password'
      }
    },
    toggleOtpType() {
      if(this.otpType === 'password') {
        this.otpType = 'text'
      } else {
        this.otpType = 'password'
      }
    },
    toggleConfirmType() {
      if(this.confirmType === 'password') {
        this.confirmType = 'text'
      } else {
        this.confirmType = 'password'
      }
    },
    onSignin() {
      if (this.password === this.confirmPassword) {
        axios
          .post(
            `${process.env.VUE_APP_BASE_URL}/user/new-password`,
            {
              userId: this.$route.params.id,
              password: this.password,
              otp: this.otp,
            },
            { withCredentials: true }
          )
          .then((res) => {
            if (res && res.data) {
              this.$router.push("/");
            }
          })
          .catch(() => {
            const toastLiveExample = document.getElementById("liveToast");
            const toast = new window.bootstrap.Toast(toastLiveExample);
            this.errorMessage = this.$t('otp_is_wrong');
            toast.show();
          });
      } else {
        const toastLiveExample = document.getElementById("liveToast");
        const toast = new window.bootstrap.Toast(toastLiveExample);
        this.errorMessage = this.$t('password_does_not_match');
        toast.show();
      }
    },
  },
};
</script>


<style scoped>
.page-header {
  display: block;
}

.container {
  margin-left: 0;
  padding-left: 0;
  margin-right: 0;
  padding-right: 0;
  max-width: initial;
}

.email-input-holder {
  position: relative;
}

.email-icon {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 5%;
}

.password-icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0%;
}

.email-input {
  padding-left: 10%;
}

.password-input {
  padding-right: 10%;
}

.forgot-password-wrapper {
  text-align: end;
}

.main-img {
  height: 50vh;
}

.img-wrapper {
  position: relative;
}

.status-img {
  position: absolute;
  top: 35%;
  right: 15%;
  transform: scale(0.7);
}

.footer {
  font-size: 0.8rem;
}

.webglobic {
  color: #FF814F;
}
</style>

<style>
.forgot-password-wrapper a {
  text-decoration: underline;
  color: #FF814F;
  font-size: 0.8rem;
}
</style>
