import { createRouter, createWebHistory } from "vue-router";
import Tables from "@/views/Tables.vue";
import Settings from "@/views/Settings.vue";
import Summary from "@/views/Summary.vue";
import SignIn from "@/views/SignIn.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import NewPassword from "@/views/NewPassword.vue";
import AddTimesheet from "@/views/AddTimesheet.vue";
// import i18n from '../i18n';

const routes = [
    {
        path: "/",
        name: "/",
        redirect: "/sign-in",
    },
    {
        path: "/projects",
        name: "Tables",
        component: Tables,
    },
    {
        path: "/timesheet",
        name: "Register Timesheet",
        component: AddTimesheet,
    },
    {
        path: "/timesheet/:id",
        name: "Update Timesheet",
        component: AddTimesheet,
    },
    {
        path: "/settings",
        name: "Settings",
        component: Settings,
    },
    {
        path: "/overview",
        name: "Overview",
        component: Tables,
    },
    {
        path: "/summary",
        name: "Summary",
        component: Summary,
    },
    {
        path: "/projects/:id/timesheet",
        name: "Add Timesheet",
        component: AddTimesheet,
    },
    {
        path: "/projects/:id/:tsid",
        name: "Edit Timesheet",
        component: AddTimesheet,
    },
    {
        path: "/sign-in",
        name: "Sign In",
        component: SignIn,
    },
    {
        path: "/reset-password",
        name: "Reset Password",
        component: function () {
            if (window.location.origin == process.env.VUE_APP_DEMO_ORIGIN) {
                return SignIn
            } else {
                return ResetPassword
            }
        }
    },
    {
        path: "/new-password/:id",
        name: "New Password",
        component: NewPassword,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
});
// router.beforeEach((to, from, next) => {
//   if (to.name) {
//     document.title = i18n.global.t(`routes.${to.name}`);
//   }
//   next();
// });
export default router;
