<template>
  <vsud-alert :dismissible="dismiss" @dismiss-alert="dismissAlert" :color="color">{{ message }}</vsud-alert>
  <div class="mian-content my-5">

    <div class="position-fixed top-0 end-0 p-3 opacity-100" style="z-index: 11">
      <div id="liveToast" class="toast bg-warning opacity=100" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">{{ $t('error') }}</strong>
          <button type="button" class="btn-close bg-primary" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ errorMessage }}
        </div>
      </div>
    </div>

    <div class="row-6">
      <div class="col mx-md-5 px-md-5">
        <div class="card shadow-lg">
          <div class="card-header h5 fw-bolder">{{ $t('settings') }}</div>
          <div class="card-body">
            <form @submit.prevent="handleSubmit">
              <div class="row g-2">
                <div class="col-md">
                  <div class="form-label text-capitalize">{{ $t('first_name') }}</div>
                  <input type="text" class="form-control" v-model="settingsData.firstName" />
                </div>
                <div class="col-md">
                  <div class="form-label text-capitalize">{{ $t('last_name') }}</div>
                  <input type="text" class="form-control" v-model="settingsData.lastName" />
                </div>
                <div>
                  <div class="form-label text-capitalize">{{ $t('language') }}</div>
                  <select class="form-control" v-model="settingsData.defaultLanguage">
                    <option value="en">English</option>
                    <option value="de">German</option>
                  </select>
                </div>
                <div v-if="this.$store.state.origin!=this.$store.state.demoOrigin">
                  <div class="form-label text-capitalize">{{ $t('email_id') }}</div>
                  <input type="email" class="form-control" v-model="settingsData.email" />
                </div>
                <div v-if="this.$store.state.origin!=this.$store.state.demoOrigin">
                  <div class="form-label text-capitalize">{{ $t('password') }}</div>
                  <input type="password" class="form-control" v-model="settingsData.password" />
                </div>
                <div v-if="this.$store.state.origin!=this.$store.state.demoOrigin">
                  <div class="form-label text-capitalize">{{ $t('confirm_password') }}</div>
                  <input type="password" class="form-control" v-model="settingsData.confirmPassword" />
                </div>
                <div>
                  <div class="form-label text-capitalize">{{ $t('phone_number') }}</div>
                  <input type="tel" class="form-control" v-model="settingsData.phoneNumber" />
                </div>
                <div>
                  <div class="form-label text-capitalize">{{ $t('date_of_birth') }}</div>
                  <input type="date" id="date-input-s" class="form-control" format="DD/mm/yyyy"
                    v-model="settingsData.dateOfBirth" />
                </div>
                <div class="d-flex my-3 justify-content-end">
                  <vsud-button>{{ $t('save') }}</vsud-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "../components/VsudButton.vue";
import VsudAlert from "../components/VsudAlert.vue";
import i18n from '../i18n';
import { mapMutations } from "vuex";
import axios from "axios";
import { useToast } from 'vue-toastification';
export default {
  components: { VsudButton, VsudAlert },
  data() {
    return {
      settingsData: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        dateOfBirth: "",
        defaultLanguage: "",
      },
      message: "",
      dismiss: false,
      color: "warning",
      errorMessage: "",
    };
  },

  created() {
    var _isUserLoggedIn = localStorage.isUserLoggedIn
    if (_isUserLoggedIn === undefined) {
      localStorage.isUserLoggedIn = "no"
      _isUserLoggedIn = localStorage.isUserLoggedIn
    }
    if (_isUserLoggedIn === "no") {
      this.$router.push({
        path: `/sign-in`,
      })
      return
    }
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_BASE_URL}/user/me`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data && res.data.payload && res.data.payload.user) {
          const user = res.data.payload.user;
          this.settingsData.firstName = user.firstName;
          this.settingsData.lastName = user.lastName;
          this.settingsData.email = user.username;
          this.settingsData.phoneNumber = user.phoneNumber;
          this.settingsData.defaultLanguage = user.language;
          i18n.locale = user.language;
          this.settingsData.dateOfBirth = new Date(user.dateOfBirth)
            .toISOString()
            .slice(0, 10);
        }
      });
  },
  methods: {
    ...mapMutations([
      "setUserLanguage",
    ]),
    validatePhone(phone) {
      return String(phone).match(/^((\+91?)|\+)?[7-9][0-9]{9}$/);
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    handleSubmit() {
      const toastLiveExample = document.getElementById("liveToast");
      const toast = new window.bootstrap.Toast(toastLiveExample);
      const {
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        dateOfBirth,
      } = this.settingsData;
      const toast2 = useToast()
      if (firstName === "") {
        this.errorMessage = this.$t('please_enter_your_first_name');
        toast.show();
        return;
      }
      if (lastName === "") {
        this.errorMessage = this.$t('please_enter_your_last_name');
        toast.show();
        return;
      }
      if (email === "") {
        this.errorMessage = this.$t('please_enter_your_email');
        toast.show();
        return;
      }
      if (!this.validateEmail(email)) {
        this.errorMessage = this.$t('please_enter_your_last_email_id');
        toast.show();
        return;
      }
      if (password !== "") {
        if (password.length < 8) {
          this.errorMessage = this.$t('please_enter_your_password8');
          toast.show();
          return;
        }
        if (password.length > 20) {
          this.errorMessage = this.$t('please_enter_your_password20');
          toast.show();
          return;
        }
        if (password.search(/[a-z]/i) < 0) {
          this.errorMessage = this.$t('please_enter_your_password_one_letter');
          toast.show();
          return;
        }
        if (password.search(/[0-9]/) < 0) {
          this.errorMessage = this.$t('please_enter_your_password_one_digit');
          toast.show();
          return;
        }
      }
      if (password !== "" && confirmPassword !== password) {
        this.errorMessage = this.$t('passwords_do_not_match');
        toast.show();
        return;
      }
      if (dateOfBirth === "") {
        this.errorMessage = this.$t('please_enter_your_date_of_birth');
        toast.show();
        return;
      }
      if (new Date(dateOfBirth).getTime() > Date.now()) {
        this.errorMessage = this.$t('date_of_birth_cannot_be_of_future_date');
        toast.show();
        return;
      }
      const body = {
        username: this.settingsData.email,
        firstName: this.settingsData.firstName,
        lastName: this.settingsData.lastName,
        phoneNumber: this.settingsData.phoneNumber,
        dateOfBirth: this.settingsData.dateOfBirth,
        language: this.settingsData.defaultLanguage,
      };
      i18n.locale = this.settingsData.defaultLanguage;
      if (this.settingsData.password) {
        body.password = this.settingsData.password;
      }
      axios
        .patch(`${process.env.VUE_APP_BASE_URL}/user/me`, body, {
          withCredentials: true,
        })
        .then(() => {
          // const toastLiveExample = document.getElementById("liveToast");
          // const toast = new window.bootstrap.Toast(toastLiveExample);
          // this.errorMessage = this.$t('details_saved_successfully');
          // toast2.success("Details saved successfully");
          toast2.success(this.$t('details_saved_successfully'));
          // toast.show();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          // setTimeout(() => {
          //   this.$router.push({
          //     path: '/overview',
          //   });
          // }, 3000);
          return true;
        }).catch(() => {
          const toastLiveExample = document.getElementById("liveToast");
          const toast = new window.bootstrap.Toast(toastLiveExample);
          this.errorMessage = this.$t('error_saving_details');
          toast.show();
          return true;
        });
    },
    dismissALert() {
      this.dismiss = false;
    },
  },
};
</script>

<style scoped></style>