<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse :navText="$t('timesheet')" :to="{ name: 'Overview' }">
          <template #icon>
            <overview/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse :navText="$t('summary')" :to="{ name: 'Summary' }">
          <template #icon>
            <overview/> 
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse :navText="$t('settings')" :to="{ name: 'Settings' }">
          <template #icon>
           <settings-outline/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item logout-button" @click="logout">
        <!-- <sidenav-collapse navText="Logout" :to="{ name: 'Sign In' }">
          <template #icon>
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/>
               <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22zm7-6v-3h-8v-2h8V8l5 4-5 4z"/> </g> </svg>
          </template>
          </sidenav-collapse> -->

          <div class="container">
            <div class="row">
              <div class="col-1 px-4">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/>
               <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22zm7-6v-3h-8v-2h8V8l5 4-5 4z"/> </g> </svg>
        </div>
              <div class="col-9 px-3">{{ $t('logout') }}</div>
            </div>
          </div>

      </li>
    </ul>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
// import CreditCard from "../../components/Icon/CreditCard.vue";
// import Box3d from "../../components/Icon/Box3d.vue";
// import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
// import Document from "../../components/Icon/Document.vue";
import Overview from "../../components/Icon/Overview.vue"
import SettingsOutline from "../../components/Icon/SettingsOutline.vue"

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Timesheet",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    SidenavCollapse,
    Overview,
    SettingsOutline,
    // CreditCard,
    // Box3d,
    // CustomerSupport,
    // Document,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },

    logout(){
      localStorage.isUserLoggedIn = "no"
      this.$router.push({
        path:`/sign-in`
      })
    },
  },
};
</script>

<style scoped>
.logout-button {
  position: absolute;
  bottom: 2%;
  width:20px;
}

svg {
  height: 18px;
  width:16px;
  transform: scale(1.5);
}

.logout-button:hover {
    cursor:pointer;
}

</style>
