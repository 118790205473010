<template>
  <div class="row">
    <div class="col-12">
      <projects-table/>
    </div>
    </div>
</template>
<script>
import ProjectsTable from "./components/ProjectsTable";

export default {
  name: "tables",
  components: {
    ProjectsTable,
  },

  mounted(){
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
  },

  created(){
    var _isUserLoggedIn = localStorage.isUserLoggedIn
    if(_isUserLoggedIn === undefined)
    {
      localStorage.isUserLoggedIn = "no"
      _isUserLoggedIn = localStorage.isUserLoggedIn
    }
    if(_isUserLoggedIn === "no"){
      this.$router.push({
        path: `/sign-in`,
      })
      return
    }
  },

};
</script>
