<template>
  <main class="mt-0 main-content main-content-bg">
    <div class="position-fixed top-0 end-0 p-3 opacity-100" style="z-index: 11">
      <div
        id="liveToast"
        class="toast bg-warning opacity=100"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="toast-header">
          <strong class="me-auto">{{ $t('error') }}</strong>
          <button
            type="button"
            class="btn-close bg-primary"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
        <div class="toast-body">
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <section>
      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-6 d-flex flex-column vh-100 bg-white">
              <div class="mt-5 mx-5 card card-plain">
                <div class="pb-0 card-header text-start d-flex"><svg width="31" height="22" viewBox="0 0 31 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.93751 0.132324H23.4543L13.5167 14.8769H0L9.93751 0.132324Z" fill="#FF814F"/>
<path d="M10.5297 16.2436L6.68066 21.9546H20.8386L30.7761 7.20996H20.3267L14.2382 16.2436H10.5297Z" fill="#F9CEBD"/>
</svg>

                  <h6 class="mb-0 mx-2">
                    DailyTime
                  </h6>
                </div>
                <div class="mt-5 pb-0 card-header text-start">
                  <h3 class="mb-0">
                    {{ $t('reset_password') }}!
                  </h3>
                </div>
                <div class="card-body">
                  <label>{{ $t('email_address') }}*</label>
                  <div class="form-group">
                    <div class="email-input-holder">
                      <input
                        id="username"
                        type="text"
                        class="form-control form-control-default email-input"
                        name="username"
                        v-model="username"
                        :placeholder="$t('username')"
                      /><svg class="email-icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.99997 9.39961C8.32546 9.39961 9.39997 8.32509 9.39997 6.99961C9.39997 5.67413 8.32546 4.59961 6.99997 4.59961C5.67449 4.59961 4.59998 5.67413 4.59998 6.99961C4.59998 8.32509 5.67449 9.39961 6.99997 9.39961Z" stroke="#72758A" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.4 4.59963V7.59962C9.4 8.07701 9.58965 8.53485 9.92721 8.87242C10.2648 9.20998 10.7226 9.39962 11.2 9.39962C11.6774 9.39962 12.1352 9.20998 12.4728 8.87242C12.8104 8.53485 13 8.07701 13 7.59962V6.99962C12.9999 5.64544 12.5417 4.33111 11.7 3.27035C10.8582 2.20958 9.68233 1.46476 8.36359 1.15701C7.04484 0.84925 5.66076 0.996653 4.43641 1.57525C3.21206 2.15384 2.21944 3.1296 1.61996 4.34386C1.02048 5.55812 0.849385 6.93947 1.13451 8.26329C1.41963 9.58712 2.14419 10.7756 3.19038 11.6354C4.23657 12.4952 5.54286 12.9758 6.89684 12.9991C8.25083 13.0224 9.57287 12.587 10.648 11.7636" stroke="#72758A" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                    </div>
                  </div>
                  <div class="text-center">
                    <vsud-button
                      v-on:click="onSignin"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="success"
                      full-width
                      >{{ $t('reset_password') }}
                    </vsud-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-7 col-xl-8">
              <div class="pt-6 pb-2 px-5 card card-plain vh-100 justify-content-around">
                <div class="pb-0 text-start">
                  <h3 class="mb-0 text-center">
                    {{ $t('dailytime_dashboard') }}
                  </h3>
                </div>
                <div class="pb-0 text-start">
                  <p class="mt-2 px-6 text-center">
                    {{ $t('easy_to_use_platform') }}
                  </p>
                </div>
                <div></div>
                <div class="text-center img-wrapper">
            <img
            class="main-img"
              src="@/assets/img/login-bg.png"
              alt="login-bg"
            />
            <!-- <img
            class="status-img"
              src="@/assets/img/login-status.png"
              alt="login-status"
            /> -->
            </div>
            <div class="d-flex flex-row justify-content-between footer">
              <div>&copy; {{ $t('all_rights_reserved_dailyTime') }}</div>
            <div>{{ $t('developed_by_webGlobic') }}<a href="https://www.webglobic.de/" target="_blank" class="webglobic"> WebGlobic Technologies GmbH</a></div>
            </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "SignIn",
  components: {
    VsudButton,
  },
  data() {
    return {
      username: "",
      password: "",
      errorMessage: "",
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations([
      "toggleEveryDisplay",
      "toggleHideConfig",
      "setCompanyName",
    ]),
    onSignin() {
      axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/user/reset-password`,
          {
            email: this.username,
          },
          { withCredentials: true }
        )
        .then((res) => {
          if (res && res.data && res.data.payload && res.data.payload.userId) {
            this.$router.push(`/new-password/${res.data.payload.userId}`);
          } else {
            const toastLiveExample = document.getElementById("liveToast");
            const toast = new window.bootstrap.Toast(toastLiveExample);
            this.errorMessage = this.$t('something_went_wrong');
            toast.show();
          }
        })
        .catch(() => {
          const toastLiveExample = document.getElementById("liveToast");
          const toast = new window.bootstrap.Toast(toastLiveExample);
          this.errorMessage = this.$t('email_does_not_exist');
          toast.show();
        });
    },
  },
};
</script>

<style scoped>
.page-header {
  display: block;
}

.container {
  margin-left: 0;
  padding-left: 0;
  margin-right: 0;
  padding-right: 0;
  max-width: initial;
}

.email-input-holder {
  position: relative;
}

.email-icon {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 5%;
}

.password-icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0%;
}

.email-input {
  padding-left: 10%;
}

.password-input {
  padding-right: 10%;
}

.forgot-password-wrapper {
  text-align: end;
}

.main-img {
  height: 50vh;
}

.img-wrapper {
  position: relative;
}

.status-img {
  position: absolute;
  top: 35%;
  right: 15%;
  transform: scale(0.7);
}

.footer {
  font-size: 0.8rem;
}

.webglobic {
  color: #FF814F;
}
</style>

<style>
.forgot-password-wrapper a {
  text-decoration: underline;
  color: #FF814F;
  font-size: 0.8rem;
}
</style>
