/**
=========================================================
* Vue Soft UI Dashboard - v2.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import vSelect from "vue-select";
import axios from "axios";
import setupI18n from './i18n';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

let compName = "";

window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle.js");

axios.get(`${process.env.VUE_APP_BASE_URL}/user/settings`).then((res) => {
  if (res && res.data && res.data.payload && res.data.payload.settings) {
    compName = res.data.payload.settings.compName;
    store.commit("setCompanyName", compName);
  }
});

(async () => {
  const i18n = await setupI18n(router);

  const appInstance = createApp(App);
  appInstance.use(store);
  appInstance.use(router);
  appInstance.use(Toast, options);
  appInstance.use(SoftUIDashboard);
  appInstance.component("Datepicker", Datepicker);
  appInstance.component("v-select", vSelect);
  appInstance.use(i18n).mount("#app");
})();


