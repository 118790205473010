<template>
  <main class="mt-0 main-content main-content-bg min-vh-100 bg-white">
    <div
      class="modal fade"
      id="submitModal"
      tabindex="-1"
      aria-labelledby="submitModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="submitModalLabel">{{ $t('confirmation') }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <div class="conatiner">
              <div class="row">
                <div class="col-1">
                  <img
                    src="../assets/img/warning.png"
                    class="warning_img_style"
                  />
                </div>
                <div class="col-10 mx-md-n2">
                  {{ $t('you_want_submit_timesheet') }}?
                  <br />
                  {{ $t('once_submitted_you_can_not_edit') }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="onSignin('draft')"
            >
            {{ $t('save_as_draft') }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="onSignin"
            >
            {{ $t('submit') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Withdraw timesheet -->

    <div
      class="modal fade"
      id="withdrawModal"
      tabindex="-1"
      aria-labelledby="withdrawModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="withdrawModalLabel">{{ $t('confirmation') }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <div class="conatiner">
              <div class="row">
                <div class="col-1">
                  <img
                    src="../assets/img/warning.png"
                    class="warning_img_style"
                  />
                </div>
                <div class="col-11 mx-md-n2">
                  {{ $t('withdraw_submission') }}?
                  <br />
                  {{ $t('draft_status') }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
            {{ $t('no') }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="withdraw"
            >
            {{ $t('yes') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- End of Withdraw timesheet -->

    <!-- Add Project Modal Dialog -->

    <div
      class="modal fade"
      id="addProjectModal"
      tabindex="-1"
      aria-labelledby="addProjectModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addProjectModalLabel">
              {{ $t('select_a_Project') }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="margin: 0 auto">
            <!--Project-->
            <div class="mx-n2">{{ $t('choose_project') }}:</div>
            <div class="col-11 mx-md-n2">
              <div class="col-3 mt-2 dropdown-inputs">
                <v-select
                  label="projectName"
                  :options="projects"
                  v-model="this.newSelectedProject"
                  @option:selected="setSelectedProject($event, item)"
                  :reduce="(i) => i.projectName"
                >
                </v-select>
              </div>
            </div>
            

            <div class="mx-n2 mt-2 modal_error_cs" v-if="this.projectModalError">{{ $t('project_empty') }}!</div>
            <!--Activity-->
            <div class="mx-n2 mt-4">{{ $t('choose_activity') }}:</div>
            <div class="col-11 mx-md-n2">
              <div class="col-3 mt-2 dropdown-inputs">
                <v-select
                  label="activityName"
                  v-model="this.newSelectedActivity"
                  :options="
                    this.newSelectedProject
                      ? projects.find(
                          (i) => i.projectName === this.newSelectedProject
                        )
                        ? projects
                            .find(
                              (i) => i.projectName === this.newSelectedProject
                            )
                            .activities.filter(
                              (item) => item.isSelected == false
                            )
                        : []
                      : []
                  "
                  @option:selected="setSelectedActivity($event, _item)"
                  :reduce="(i) => i.activityName"
                >
                <template v-slot:no-options>{{ $t('choose_activity') }}</template>
                </v-select>
              </div>
            </div>

            <div class="mx-n2 mt-2 modal_error_cs" v-if="this.activityModalError">{{ $t('activity_empty') }}!</div>
          </div>


          <div class="modal-footer md-footer-cs">
            <button
              id="closeAddProjectModal"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="resetAddProjectDialog"
            >
            {{ $t('close') }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
               @click="setNewProject"
            >
            {{ $t('add_project') }}
            </button>
          </div>


        </div>
      </div>
    </div>

    <!-- End of Add Project Modal Dialog -->

    <div class="position-fixed top-0 end-0 p-3 opacity-100" style="z-index: 11">
      <div
        id="liveToast"
        class="toast bg-warning opacity=100"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="toast-header">
          <strong class="me-auto">{{ $t('error') }}</strong>
          <button
            type="button"
            class="btn-close bg-primary"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
        <div class="toast-body">
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <section>
      <div class="page-header">
        <div class="container mx-0 my-n3 min-vh-100 mw-100 cs_font_family">
          <div class="row">
            <div class="mx-auto col-xl-12 col-12 col-md-12 d-flex flex-column">
              <div class="card card-plain">
                <div class="card-body">
                  <div class="col-12">
                    <div class="row justify-content-between">
                      <div
                        class="col-12 d-flex align-items-center zero-padding"
                      >
                        <div class="col-12" style="max-width: 100%">
                          <!-- <div>Week of {{ currentWeek }}</div> -->

                          <div class="row flex-wrap ps-4">
                            <div class="col-4 d-flex justify-content-start">
                              <div class="row flex-wrap">
                                <div
                                  class="col-4 d-flex align-items-center mx-n2"
                                >
                                  <h6 class="cs_font_family">{{ $t('week') }}:</h6>
                                </div>
                                <div
                                  class="col-6 align-items-center mx-n4 mb-2"
                                >
                                  <Datepicker
                                    class="full-width"
                                    v-model="date"
                                    weekPicker
                                    autoApply
                                    :clearable="false"
                                    @closed="fetchTimesheet"
                                    :format="format"
                                    :disabledDates="
                                      (date) => date >= new Date()
                                    "
                                  >
                                  </Datepicker>
                                </div>
                              </div>
                            </div>
                            <div
                              class="
                                col-xxl-4 col-xl-4 col-sm-12 col-xs-12
                                d-flex
                                align-items-center
                                mb-2
                                justify-content-center
                              "
                            >
                              <div
                                :class="
                                  isDraft
                                    ? 'text-center ts-status-draft'
                                    : 'text-center ts-status-submitted'
                                "
                                v-if="false"
                              >
                                <div>{{ isDraft ? "" : this.$t('submitted') }}</div>
                              </div>
                            </div>
                            <div
                              class="
                                col-4
                                d-flex
                                justify-content-lg-end
                                justify-content-sm-start
                                justify-content-start
                              "
                            >
                              <div>
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  @click="exitPage"
                                >
                                {{ $t('close') }}
                                </button>
                              </div>
                            </div>
                          </div>

                          <!-- <div style="padding-left:4px">
                          <button type="button" class="btn btn-primary" @click="exitPage">Close</button>
                          </div>

                          <label>Week</label>
                          <div class="col form-group full-width">
                            <Datepicker
                              class="full-width"
                              v-model="date"
                              weekPicker
                              autoApply
                              :clearable="false"
                              @closed="fetchTimesheet"
                              :format="format"
                              :disabledDates="(date) => date >= new Date()"
                            ></Datepicker>
                          </div> -->
                        </div>
                      </div>
                      <!-- <div class="col mt-n4 text-center col-8 zero-padding full-width">
                        <vsud-button
                          v-on:click="fetchPreviousTimesheet"
                          class="my-4 mb-2 full-width"
                          full-width
                          >Copy Previous Week
                        </vsud-button>
                      </div> -->
                    </div>
                  </div>
                  <div class="custom-window">
                    <!-- <div class="col-12 mt-2 cw_cs_style">
                      <div class="row mb-2 
                            px-2 flex-nowrap align-items-center highlight_bg">
                        <div
                          class="
                            col-2
                            ml-2
                            d-flex
                            align-items-center
                            fron-time-inputs
                          "
                        >
                          <h6 class="time-inputs">Date</h6>
                        </div>
                        <div class="col time-inputs weekly-names"><h6>Mon</h6></div>
                        <div class="col time-inputs weekly-names"><h6>Tue</h6></div>
                        <div class="col time-inputs weekly-names"><h6>Wed</h6></div>
                        <div class="col time-inputs weekly-names"><h6>Thu</h6></div>
                        <div class="col time-inputs weekly-names"><h6>Fri</h6></div>
                        <div class="col time-inputs weekly-names"><h6>Sat</h6></div>
                        <div class="col time-inputs weekly-names"><h6>Sun</h6></div>
                      </div>
                      <div class="row px-2 mt-n4 flex-nowrap">
                        <div
                          class="
                            col-2
                            d-flex
                            align-items-center
                            fron-time-inputs
                          "
                        >
                         <h6 class="time-inputs">From Time</h6>
                        </div>
                        <div class="col time-inputs weekly-names">
                          <input
                            type="time"
                            class="form-control form-control-default"
                            v-model="workHours[0].from"
                          />
                        </div>
                        <div class="col time-inputs weekly-names">
                          <input
                            type="time"
                            class="form-control form-control-default"
                            v-model="workHours[1].from"
                          />
                        </div>
                        <div class="col time-inputs weekly-names">
                          <input
                            type="time"
                            class="form-control form-control-default"
                            v-model="workHours[2].from"
                          />
                        </div>
                        <div class="col time-inputs weekly-names">
                          <input
                            type="time"
                            class="form-control form-control-default"
                            v-model="workHours[3].from"
                          />
                        </div>
                        <div class="col time-inputs weekly-names">
                          <input
                            type="time"
                            class="form-control form-control-default"
                            v-model="workHours[4].from"
                          />
                        </div>
                        <div class="col time-inputs weekly-names">
                          <input
                            type="time"
                            class="form-control form-control-default"
                            v-model="workHours[5].from"
                          />
                        </div>
                        <div class="col time-inputs weekly-names">
                          <input
                            type="time"
                            class="form-control form-control-default"
                            v-model="workHours[6].from"
                          />
                        </div>
                      </div>
                      <div class="row mt-n3 px-2  flex-nowrap">
                        <div
                          class="
                            col-2
                            d-flex
                            align-items-center
                            fron-time-inputs
                          "
                        >
                          <h6 class="time-inputs">To Time</h6>
                        </div>
                        <div class="col time-inputs weekly-names">
                          <input
                            type="time"
                            class="form-control form-control-default"
                            v-model="workHours[0].to"
                          />
                        </div>
                        <div class="col time-inputs weekly-names">
                          <input
                            type="time"
                            class="form-control form-control-default"
                            v-model="workHours[1].to"
                          />
                        </div>
                        <div class="col time-inputs weekly-names">
                          <input
                            type="time"
                            class="form-control form-control-default"
                            v-model="workHours[2].to"
                          />
                        </div>
                        <div class="col time-inputs weekly-names">
                          <input
                            type="time"
                            class="form-control form-control-default"
                            v-model="workHours[3].to"
                          />
                        </div>
                        <div class="col time-inputs weekly-names">
                          <input
                            type="time"
                            class="form-control form-control-default"
                            v-model="workHours[4].to"
                          />
                        </div>
                        <div class="col time-inputs weekly-names">
                          <input
                            type="time"
                            class="form-control form-control-default"
                            v-model="workHours[5].to"
                          />
                        </div>
                        <div class="col time-inputs weekly-names">
                          <input
                            type="time"
                            class="form-control form-control-default"
                            v-model="workHours[6].to"
                          />
                        </div>
                      </div>
                    </div> -->

                    <!-- Start of New Code -->
<!-- 
                    <div class="col-12 mb-n3" v-if="false">
                      <div
                        class="row mt-1 flex-wrap"
                        v-for="(_item, index) in selectedProjects"
                        :key="index"
                      >
                        <div class="col-12 mb-n4"><h6>Choose Project:</h6></div>

                        <div class="row mt-2 flex-nowrap">
                          <div class="col-3 mt-4 dropdown-inputs">
                            <v-select
                              label="projectName"
                              :options="projects"
                              v-model="_item.projectName"
                              @option:selected="setNewActivity($event, _item)"
                              :reduce="(i) => i.projectName"
                            >
                            </v-select>
                          </div>

                          <div class="col-1 align-items-center">
                            <vsud-button
                              v-on:click="deleteNewProject(index)"
                              class="icon-button close_btn_style"
                              color="danger"
                              full-width
                              ><div class="icon-translate">
                                <svg
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.24264 0.964451C8.8955 0.617314 8.33278 0.617313 7.98564 0.96445L5 3.95009L2.01436 0.96445C1.66722 0.617313 1.1045 0.617314 0.757359 0.964451C0.410222 1.31159 0.410221 1.87431 0.757358 2.22145L3.743 5.20709L0.757358 8.19273C0.410221 8.53987 0.410223 9.10259 0.75736 9.44973C1.1045 9.79687 1.66722 9.79687 2.01436 9.44973L5 6.46409L7.98564 9.44973C8.33278 9.79687 8.89551 9.79687 9.24264 9.44973C9.58978 9.1026 9.58978 8.53987 9.24264 8.19273L6.257 5.20709L9.24264 2.22145C9.58978 1.87431 9.58978 1.31159 9.24264 0.964451Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                            </vsud-button>
                          </div>
                        </div>

                        <div class="col-8"></div>
                        <div class="col-12 hr_width">
                          <div
                            class="row flex-nowrap mb-n3 mt-n1"
                            v-if="_item.activities.length !== 0"
                          >
                            <div class="col-3 mt-3 mr-5 dropdown-inputs">
                              <h6>Add Activity:</h6>
                            </div>
                            <div
                              class="
                                col
                                d-flex
                                align-items-center
                                mt-3
                                week_name_style
                              "
                            >
                              <h6>Mon</h6>
                            </div>
                            <div
                              class="
                                col
                                d-flex
                                align-items-center
                                mt-3
                                week_name_style
                              "
                            >
                              <h6>Tue</h6>
                            </div>
                            <div
                              class="
                                col
                                d-flex
                                align-items-center
                                mt-3
                                week_name_style
                              "
                            >
                              <h6>Wed</h6>
                            </div>
                            <div
                              class="
                                col
                                d-flex
                                align-items-center
                                mt-3
                                week_name_style
                              "
                            >
                              <h6>Thu</h6>
                            </div>
                            <div
                              class="
                                col
                                d-flex
                                align-items-center
                                mt-3
                                week_name_style
                              "
                            >
                              <h6>Fri</h6>
                            </div>
                            <div
                              class="
                                col
                                d-flex
                                align-items-center
                                mt-3
                                week_name_style
                              "
                            >
                              <h6>Sat</h6>
                            </div>
                            <div
                              class="
                                col
                                d-flex
                                align-items-center
                                mt-3
                                week_name_style
                              "
                            >
                              <h6>Sun</h6>
                            </div>
                            <div class="col pe-6" v-if="false">
                              <vsud-button
                                v-on:click="deleteNewRow(_item, index)"
                                class="my-4 mb-2 icon-button"
                                color="danger"
                                full-width
                                ><div class="icon-translate">
                                  <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.24264 0.964451C8.8955 0.617314 8.33278 0.617313 7.98564 0.96445L5 3.95009L2.01436 0.96445C1.66722 0.617313 1.1045 0.617314 0.757359 0.964451C0.410222 1.31159 0.410221 1.87431 0.757358 2.22145L3.743 5.20709L0.757358 8.19273C0.410221 8.53987 0.410223 9.10259 0.75736 9.44973C1.1045 9.79687 1.66722 9.79687 2.01436 9.44973L5 6.46409L7.98564 9.44973C8.33278 9.79687 8.89551 9.79687 9.24264 9.44973C9.58978 9.1026 9.58978 8.53987 9.24264 8.19273L6.257 5.20709L9.24264 2.22145C9.58978 1.87431 9.58978 1.31159 9.24264 0.964451Z"
                                      fill="white"
                                    />
                                  </svg>
                                </div>
                              </vsud-button>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 hr_width">
                          <div
                            class="row mt-1 mb-n2 flex-nowrap"
                            v-for="(item, index) in _item.activities"
                            :key="index"
                          >
                            <div class="col-3 mt-4 dropdown-inputs">
                              <v-select
                                @open="activateTooltips"
                                label="activityName"
                                :options="
                                  _item.projectName
                                    ? projects.find(
                                        (i) =>
                                          i.projectName === _item.projectName
                                      )
                                      ? projects
                                          .find(
                                            (i) =>
                                              i.projectName ===
                                              _item.projectName
                                          )
                                          .activities.filter(
                                            (item) => item.isSelected == false
                                          )
                                      : []
                                    : []
                                "
                                @option:selected="
                                  selectedActivity($event, index, item)
                                "
                                v-model="item.activityName"
                                :reduce="(i) => i.activityName"
                              >
                                <template v-slot:option="option">
                                  <span
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="option.activityDescription"
                                  >
                                    {{ option.activityName }}
                                  </span>
                                </template>
                              </v-select>
                            </div>
                            <div class="col d-flex align-items-center mt-3">
                              <input
                                type="number"
                                class="
                                  form-control form-control-default
                                  hour-inputs
                                "
                                :value="item.hours[0]"
                                @change="
                                  validateNewHours($event, item, index, 0)
                                "
                              />
                            </div>
                            <div class="col d-flex align-items-center mt-3">
                              <input
                                type="number"
                                class="
                                  form-control form-control-default
                                  hour-inputs
                                "
                                :value="item.hours[1]"
                                @change="
                                  validateNewHours($event, item, index, 1)
                                "
                              />
                            </div>
                            <div class="col d-flex align-items-center mt-3">
                              <input
                                type="number"
                                class="
                                  form-control form-control-default
                                  hour-inputs
                                "
                                :value="item.hours[2]"
                                @change="
                                  validateNewHours($event, item, index, 2)
                                "
                              />
                            </div>
                            <div class="col d-flex align-items-center mt-3">
                              <input
                                type="number"
                                class="
                                  form-control form-control-default
                                  hour-inputs
                                "
                                :value="item.hours[3]"
                                @change="
                                  validateNewHours($event, item, index, 3)
                                "
                              />
                            </div>
                            <div class="col d-flex align-items-center mt-3">
                              <input
                                type="number"
                                class="
                                  form-control form-control-default
                                  hour-inputs
                                "
                                :value="item.hours[4]"
                                @change="
                                  validateNewHours($event, item, index, 4)
                                "
                              />
                            </div>
                            <div class="col d-flex align-items-center mt-3">
                              <input
                                type="number"
                                class="
                                  form-control form-control-default
                                  hour-inputs
                                "
                                :value="item.hours[5]"
                                @change="
                                  validateNewHours($event, item, index, 5)
                                "
                              />
                            </div>
                            <div class="col d-flex align-items-center mt-3">
                              <input
                                type="number"
                                class="
                                  form-control form-control-default
                                  hour-inputs
                                "
                                :value="item.hours[6]"
                                @change="
                                  validateNewHours($event, item, index, 6)
                                "
                              />
                            </div>
                            <div
                              class="col-1 pe-6"
                              v-if="index !== _item.activities.length - 1"
                            >
                              <vsud-button
                                v-on:click="deleteNewRow(_item, index)"
                                class="my-4 mb-2 icon-button"
                                color="danger"
                                full-width
                                ><div class="icon-translate">
                                  <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.24264 0.964451C8.8955 0.617314 8.33278 0.617313 7.98564 0.96445L5 3.95009L2.01436 0.96445C1.66722 0.617313 1.1045 0.617314 0.757359 0.964451C0.410222 1.31159 0.410221 1.87431 0.757358 2.22145L3.743 5.20709L0.757358 8.19273C0.410221 8.53987 0.410223 9.10259 0.75736 9.44973C1.1045 9.79687 1.66722 9.79687 2.01436 9.44973L5 6.46409L7.98564 9.44973C8.33278 9.79687 8.89551 9.79687 9.24264 9.44973C9.58978 9.1026 9.58978 8.53987 9.24264 8.19273L6.257 5.20709L9.24264 2.22145C9.58978 1.87431 9.58978 1.31159 9.24264 0.964451Z"
                                      fill="white"
                                    />
                                  </svg>
                                </div>
                              </vsud-button>
                            </div>
                            <div class="col-1 pe-6" v-else>
                              <vsud-button
                                v-on:click="addNewActivity(_item)"
                                class="
                                  my-4
                                  mb-2
                                  plus-button
                                  hours-inputs
                                  icon-button
                                "
                                variant="gradient"
                                color="success"
                                full-width
                                ><div class="icon-translate">
                                  <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0C5.50908 0 5.11117 0.397907 5.11117 0.888833V5.11117H0.888833C0.397907 5.11117 0 5.50908 0 6C0 6.49093 0.397907 6.88883 0.888833 6.88883H5.11117V11.1112C5.11117 11.6021 5.50908 12 6 12C6.49093 12 6.88883 11.6021 6.88883 11.1112V6.88883H11.1112C11.6021 6.88883 12 6.49093 12 6C12 5.50908 11.6021 5.11117 11.1112 5.11117H6.88883V0.888833C6.88883 0.397907 6.49093 0 6 0Z"
                                      fill="white"
                                    />
                                  </svg>
                                </div>
                              </vsud-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <!-- End of New Code -->

                    <!-- <div class="col-12 mt-1 mb-n3">
                      <div class="row mt-2 flex-nowrap align-items-center">
                        <div class="col-1 mb-n1 d-flex align-items-center">
                          <h4>Attendance</h4>
                        </div>
                      </div>
                    </div> -->

                    <!-- New Table for Attendance Starts here -->
                    <table class="table table table_style mb-4">
                      <thead>
                        <tr style="background: #fafbfc" class="cs_tr_title">
                          <th style="position: relative">
                            <div class="table_th_one_style cs_font_family">
                              Attendance
                            </div>
                            <!--                           
                            <div style="height:90px"></div> -->
                          </th>
                          <th>
                            <div class="cs_font_family">{{ $t('monday') }}</div>
                          </th>
                          <th><div class="cs_font_family">{{ $t('tuesday') }}</div></th>
                          <th><div class="cs_font_family">{{ $t('wednesday') }}</div></th>
                          <th><div class="cs_font_family">{{ $t('thursday') }}</div></th>
                          <th><div class="cs_font_family">{{ $t('friday') }}</div></th>
                          <th><div class="cs_font_family">{{ $t('saturday') }}</div></th>
                          <th><div class="cs_font_family">{{ $t('sunday') }}</div></th>
                          <th><div style="color: transparent">{{ $t('total') }}</div></th>
                          <th class="table_th_last_style cs_font_family"></th>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th style="position: relative">
                            <div class="mb-2 cs_font_family">{{ $t('from_time') }}:</div>
                          </th>
                          <th>
                            <input
                              type="time"
                              class="form-control mt-2 form-control-default"
                              v-model="workHours[0].from"
                            />
                          </th>
                          <th>
                            <input
                              type="time"
                              class="form-control mt-2 form-control-default"
                              v-model="workHours[1].from"
                            />
                          </th>
                          <th>
                            <input
                              type="time"
                              class="form-control mt-2 form-control-default"
                              v-model="workHours[2].from"
                            />
                          </th>
                          <th>
                            <input
                              type="time"
                              class="form-control mt-2 form-control-default"
                              v-model="workHours[3].from"
                            />
                          </th>
                          <th>
                            <input
                              type="time"
                              class="form-control mt-2 form-control-default"
                              v-model="workHours[4].from"
                            />
                          </th>
                          <th>
                            <input
                              type="time"
                              class="form-control mt-2 form-control-default"
                              v-model="workHours[5].from"
                            />
                          </th>
                          <th>
                            <input
                              type="time"
                              class="form-control mt-2 form-control-default"
                              v-model="workHours[6].from"
                            />
                          </th>
                          <th>
                            <div class="mx-3 mb-2">
                              <div v-if="true" style="color: transparent">
                                0
                              </div>
                            </div>
                          </th>
                          <th class="table_th_last_style"></th>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th style="position: relative">
                            <div class="mb-2 cs_font_family">{{ $t('to_time') }}:</div>
                          </th>
                          <th>
                            <input
                              type="time"
                              class="form-control mt-2 form-control-default"
                              v-model="workHours[0].to"
                            />
                          </th>
                          <th>
                            <input
                              type="time"
                              class="form-control mt-2 form-control-default"
                              v-model="workHours[1].to"
                            />
                          </th>
                          <th>
                            <input
                              type="time"
                              class="form-control mt-2 form-control-default"
                              v-model="workHours[2].to"
                            />
                          </th>
                          <th>
                            <input
                              type="time"
                              class="form-control mt-2 form-control-default"
                              v-model="workHours[3].to"
                            />
                          </th>
                          <th>
                            <input
                              type="time"
                              class="form-control mt-2 form-control-default"
                              v-model="workHours[4].to"
                            />
                          </th>
                          <th>
                            <input
                              type="time"
                              class="form-control mt-2 form-control-default"
                              v-model="workHours[5].to"
                            />
                          </th>
                          <th>
                            <input
                              type="time"
                              class="form-control mt-2 form-control-default"
                              v-model="workHours[6].to"
                            />
                          </th>

                          <th><div class="mx-3 mb-2"></div></th>
                          <th class="table_th_last_style"></th>
                        </tr>
                      </thead>
                    </table>

                    <!-- New Table for Attendance Ends here -->

                    <!-- <div class="col-12 mt-1 mb-n3">
                      <div class="row mt-2 flex-nowrap align-items-center">
                        <div class="col-1 mb-n1 d-flex align-items-center">
                          <h4>Time Distribution</h4>
                        </div> -->

                    <!-- <div class="col-1 d-flex align-items-center">
                            <vsud-button
                            @click="addNewProject"
                            class="
                              my-4
                              mb-2
                              plus-button
                              hours-inputs
                              icon-button
                            "
                            variant="gradient"
                            color="success"
                            full-width
                            ><div class="icon-translate">
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 0C5.50908 0 5.11117 0.397907 5.11117 0.888833V5.11117H0.888833C0.397907 5.11117 0 5.50908 0 6C0 6.49093 0.397907 6.88883 0.888833 6.88883H5.11117V11.1112C5.11117 11.6021 5.50908 12 6 12C6.49093 12 6.88883 11.6021 6.88883 11.1112V6.88883H11.1112C11.6021 6.88883 12 6.49093 12 6C12 5.50908 11.6021 5.11117 11.1112 5.11117H6.88883V0.888833C6.88883 0.397907 6.49093 0 6 0Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                          </vsud-button>
                        </div> -->
                    <!-- </div>
                    </div> -->

                    <!-- New Design Starts here -->

                    <table class="table table table_style mb-4 mt-4">
                      <thead>
                        <tr style="background: #fafbfc" class="cs_tr_title">
                          <th style="position: relative">
                            <div class="table_th_one_style_two cs_font_family">
                              {{ $t('time_distribution') }}
                            </div>
                            <!--                           
                            <div style="height:90px"></div> -->
                          </th>
                          <th>
                            <div class="cs_font_family">{{ $t('monday') }}</div>
                          </th>
                          <th><div class="cs_font_family">{{ $t('tuesday') }}</div></th>
                          <th><div class="cs_font_family">{{ $t('wednesday') }}</div></th>
                          <th><div class="cs_font_family">{{ $t('thursday') }}</div></th>
                          <th><div class="cs_font_family">{{ $t('friday') }}</div></th>
                          <th><div class="cs_font_family">{{ $t('saturday') }}</div></th>
                          <th><div class="cs_font_family">{{ $t('sunday') }}</div></th>
                          <th><div style="color: transparent">{{ $t('total') }}</div></th>
                          <th class="table_th_last_style"></th>
                        </tr>
                      </thead>
                      <thead
                        v-for="(value, key) in projectsAndActivities"
                        :key="key"
                      >
                        <tr class="title_tr_cs">
                          <th style="position: relative">
                            <div class="table_th_one_style_two cs_title_font">
                              {{ key }}
                            </div>
                            <!--                           
                            <div style="height:90px"></div> -->
                          </th>
                          <th>
                            <div class="cs_font_family"></div>
                          </th>
                          <th><div class="cs_font_family"></div></th>
                          <th><div class="cs_font_family"></div></th>
                          <th><div class="cs_font_family"></div></th>
                          <th><div class="cs_font_family"></div></th>
                          <th><div class="cs_font_family"></div></th>
                          <th><div class="cs_font_family"></div></th>
                          <th><div class="cs_font_family"></div></th>
                          <th class="table_th_last_style"></th>
                        </tr>
                        <tr v-for="(item, index) in value" :key="index">
                          <th style="position: relative">
                            <div class="table_th_one_style cs_font_family">
                              <div class="cs_font_activity">
                                {{ item.activityName }}
                                <!-- <br/>
                                <div>Test</div> -->
                              </div>
                            </div>
                          </th>
                          <th>
                            <input
                              type="number"
                              class="
                                form-control form-control-default
                                hour-inputs
                              "
                              :value="item.hours[0]"
                              @change="validateNewHours($event, item, index, 0)"
                            />
                          </th>
                          <th>
                            <input
                              type="number"
                              :value="item.hours[1]"
                              class="
                                form-control form-control-default
                                hour-inputs
                              "
                              @change="validateNewHours($event, item, index, 1)"
                            />
                          </th>
                          <th>
                            <input
                              type="number"
                              :value="item.hours[2]"
                              class="
                                form-control form-control-default
                                hour-inputs
                              "
                              @change="validateNewHours($event, item, index, 2)"
                            />
                          </th>
                          <th>
                            <input
                              type="number"
                              :value="item.hours[3]"
                              class="
                                form-control form-control-default
                                hour-inputs
                              "
                              @change="validateNewHours($event, item, index, 3)"
                            />
                          </th>
                          <th>
                            <input
                              type="number"
                              :value="item.hours[4]"
                              class="
                                form-control form-control-default
                                hour-inputs
                              "
                              @change="validateNewHours($event, item, index, 4)"
                            />
                          </th>
                          <th>
                            <input
                              type="number"
                              :value="item.hours[5]"
                              class="
                                form-control form-control-default
                                hour-inputs
                              "
                              @change="validateNewHours($event, item, index, 5)"
                            />
                          </th>
                          <th>
                            <input
                              type="number"
                              :value="item.hours[6]"
                              class="
                                form-control form-control-default
                                hour-inputs
                              "
                              @change="validateNewHours($event, item, index, 6)"
                            />
                          </th>
                          <th>
                            <div class="mx-3 mb-2">
                              {{ item.hours.reduce((a, b) => a + b, 0) }}
                            </div>
                          </th>
                          <th class="table_th_last_style">
                            <vsud-button
                              v-on:click="delProject(item, index)"
                              class="icon-button close_btn_style_new m"
                              color="danger"
                              full-width
                              v-if="isDraft"
                              ><div class="icon-translate">
                                <svg
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.24264 0.964451C8.8955 0.617314 8.33278 0.617313 7.98564 0.96445L5 3.95009L2.01436 0.96445C1.66722 0.617313 1.1045 0.617314 0.757359 0.964451C0.410222 1.31159 0.410221 1.87431 0.757358 2.22145L3.743 5.20709L0.757358 8.19273C0.410221 8.53987 0.410223 9.10259 0.75736 9.44973C1.1045 9.79687 1.66722 9.79687 2.01436 9.44973L5 6.46409L7.98564 9.44973C8.33278 9.79687 8.89551 9.79687 9.24264 9.44973C9.58978 9.1026 9.58978 8.53987 9.24264 8.19273L6.257 5.20709L9.24264 2.22145C9.58978 1.87431 9.58978 1.31159 9.24264 0.964451Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                            </vsud-button>
                          </th>
                        </tr>
                      </thead>

                      <!-- <thead>
                        <tr>
                          <td style="width: 400px">
                            <div class="btn btn-primary mt-3" @click="addNewProject">Add Project</div>
                          </td>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead> -->

                      <thead>
                        <tr
                          v-if="this.bookedHours.length === 0"
                          style="background: #fafbfc"
                        >
                          <th class="table_th_one_style" style="height: 180px">
                            <div
                              class="cs_font_family"
                              v-if="windowWidth <= 750"
                              style="padding-left: 80px"
                            >
                            {{ $t('no_records_found') }}
                            </div>
                          </th>
                          <th></th>
                          <th></th>
                          <th colspan="2" style="text-align: center">
                            <div class="th_center" v-if="windowWidth > 750">
                              <div>
                                <img
                                  class="weekly_calendar_img_style"
                                  src="../assets/img/timesheet_image.svg"
                                />
                              </div>
                              <div class="no_records_found cs_font_family">
                                {{ $t('no_records_found') }}
                              </div>
                            </div>
                          </th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th class="table_th_last_style"></th>
                        </tr>
                      </thead>
                      <thead>
                        <tr class="cs_tr_s" style="background: #fafbfc">
                          <th class="table_th_one_style">
                            <div v-if="isDraft">
                              <div
                                v-if="this.bookedHours.length === 0"
                                style="overflow-x: visible"
                              >
                                <div class="row flex-nowrap">
                                  <div
                                    class="
                                      arrow_img_style
                                      col-4
                                      justify-content-center
                                    "
                                    :class="
                                      this.bookedHours.length !== 0
                                        ? 'p-3'
                                        : 'p-2'
                                    "
                                  >
                                    <img
                                      class="arrow_img_style_icon"
                                      src="../assets/img/arrow.png"
                                    />
                                  </div>
                                  <div
                                    class="
                                      mb-4
                                      col-4
                                      arrow_text_style
                                      cs_font_family
                                      justify-content-start
                                    "
                                  >
                                    <i class="arrow_text_style_text"
                                      >{{ $t('start_adding_time_now') }}</i
                                    >
                                  </div>
                                </div>
                              </div>
                              <vsud-button
                                class="mt-1 mx-3 accent-bg cs_width"
                                style="width: 160px"
                                data-bs-toggle="modal"
                                data-bs-target="#addProjectModal"
                                @click="checkForDuplicateProjects"
                                >{{ $t('add_project') }}
                              </vsud-button>
                            </div>
                          </th>
                          <th>
                            <input
                              type="text"
                              disabled
                              class="
                                form-control form-control-default
                                hour-inputs
                                weekly-names
                              "
                              :value="totalHours[0]"
                            />
                          </th>
                          <th>
                            <input
                              type="text"
                              disabled
                              class="
                                form-control form-control-default
                                hour-inputs
                                weekly-names
                              "
                              :value="totalHours[1]"
                            />
                          </th>
                          <th>
                            <input
                              type="text"
                              disabled
                              class="
                                form-control form-control-default
                                hour-inputs
                                weekly-names
                              "
                              :value="totalHours[2]"
                            />
                          </th>
                          <th>
                            <input
                              type="text"
                              disabled
                              class="
                                form-control form-control-default
                                hour-inputs
                                weekly-names
                              "
                              :value="totalHours[3]"
                            />
                          </th>
                          <th>
                            <input
                              type="text"
                              disabled
                              class="
                                form-control form-control-default
                                hour-inputs
                                weekly-names
                              "
                              :value="totalHours[4]"
                            />
                          </th>
                          <th>
                            <input
                              type="text"
                              disabled
                              class="
                                form-control form-control-default
                                hour-inputs
                                weekly-names
                              "
                              :value="totalHours[5]"
                            />
                          </th>
                          <th>
                            <input
                              type="text"
                              disabled
                              class="
                                form-control form-control-default
                                hour-inputs
                                weekly-names
                              "
                              :value="totalHours[6]"
                            />
                          </th>
                          <th>
                            <div class="mx-3 mb-2">
                              {{ totalHours.reduce((a, b) => a + b, 0) }}
                            </div>
                          </th>
                          <th class="table_th_last_style"></th>
                        </tr>
                      </thead>
                    </table>

                    <!-- New Design Ends here -->

                    <!-- 
                    <div class="col-12 mt-5">
                      <div class="row mt-2 flex-nowrap">
                        <div
                          class="
                            col-3
                            d-flex
                            align-items-center
                            project-name-inputs
                          "
                        >
                          <div>Project</div>
                        </div>
                        <div
                          class="
                            col-3
                            d-flex
                            align-items-center
                            dropdown-inputs
                          "
                        >
                          <div>Activity</div>
                        </div>

                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="text"
                            disabled
                            class="
                              form-control form-control-default
                              hour-inputs
                              weekly-names
                            "
                            :value="'Mon'"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="text"
                            disabled
                            class="
                              form-control form-control-default
                              hour-inputs
                              weekly-names
                            "
                            :value="'Tue'"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="text"
                            disabled
                            class="
                              form-control form-control-default
                              hour-inputs
                              weekly-names
                            "
                            :value="'Wed'"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="text"
                            disabled
                            class="
                              form-control form-control-default
                              hour-inputs
                              weekly-names
                            "
                            :value="'Thu'"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="text"
                            disabled
                            class="
                              form-control form-control-default
                              hour-inputs
                              weekly-names
                            "
                            :value="'Fri'"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="text"
                            disabled
                            class="
                              form-control form-control-default
                              hour-inputs
                              weekly-names
                            "
                            :value="'Sat'"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="text"
                            disabled
                            class="
                              form-control form-control-default
                              hour-inputs
                              weekly-names
                            "
                            :value="'Sun'"
                          />
                        </div>
                        <div class="col-1 d-flex align-items-center"></div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div
                        class="row mt-2 flex-nowrap"
                        v-for="(item, index) in bookedHours"
                        :key="index"
                      >
                        <div class="col-3 mt-4 project-name-inputs">
                          <v-select
                            label="projectName"
                            :options="projects"
                            v-model="item.projectName"
                            @option:selected="setProjectName($event, item)"
                            :reduce="(i) => i.projectName"
                          >
                          </v-select>
                        </div>
                        <div class="col-3 mt-4 dropdown-inputs">
                          <v-select
                            @open="activateTooltips"
                            label="activityName"
                            :options="
                              item.projectName
                                ? projects.find(
                                    (i) => i.projectName === item.projectName
                                  )
                                  ? projects.find(
                                      (i) => i.projectName === item.projectName
                                    ).activities.filter(item => item.isSelected == false)
                                  : []
                                : []
                            "
                            @option:selected="selectedActivity($event,index, item)"
                            v-model="item.activityName"
                            :reduce="(i) => i.activityName"
                          >
                            <template v-slot:option="option">
                              <span
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                :title="option.activityDescription"
                              >
                                {{ option.activityName }}
                              </span>
                            </template>
                          </v-select>
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="number"
                            class="
                              form-control form-control-default
                              hour-inputs
                            "
                            :value="item.hours[0]"
                            @change="validateHours($event, index, 0)"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="number"
                            class="
                              form-control form-control-default
                              hour-inputs
                            "
                            :value="item.hours[1]"
                            @change="validateHours($event, index, 1)"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="number"
                            class="
                              form-control form-control-default
                              hour-inputs
                            "
                            :value="item.hours[2]"
                            @change="validateHours($event, index, 2)"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="number"
                            class="
                              form-control form-control-default
                              hour-inputs
                            "
                            :value="item.hours[3]"
                            @change="validateHours($event, index, 3)"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="number"
                            class="
                              form-control form-control-default
                              hour-inputs
                            "
                            :value="item.hours[4]"
                            @change="validateHours($event, index, 4)"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="number"
                            class="
                              form-control form-control-default
                              hour-inputs
                            "
                            :value="item.hours[5]"
                            @change="validateHours($event, index, 5)"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="number"
                            class="
                              form-control form-control-default
                              hour-inputs
                            "
                            :value="item.hours[6]"
                            @change="validateHours($event, index, 6)"
                          />
                        </div>
                        <div class="col-1 pe-6" v-if="index != 0">
                          <vsud-button
                            v-on:click="deleteRow(index)"
                            class="my-4 mb-2 icon-button"
                            color="danger"
                            full-width
                            ><div class="icon-translate">
                              <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.24264 0.964451C8.8955 0.617314 8.33278 0.617313 7.98564 0.96445L5 3.95009L2.01436 0.96445C1.66722 0.617313 1.1045 0.617314 0.757359 0.964451C0.410222 1.31159 0.410221 1.87431 0.757358 2.22145L3.743 5.20709L0.757358 8.19273C0.410221 8.53987 0.410223 9.10259 0.75736 9.44973C1.1045 9.79687 1.66722 9.79687 2.01436 9.44973L5 6.46409L7.98564 9.44973C8.33278 9.79687 8.89551 9.79687 9.24264 9.44973C9.58978 9.1026 9.58978 8.53987 9.24264 8.19273L6.257 5.20709L9.24264 2.22145C9.58978 1.87431 9.58978 1.31159 9.24264 0.964451Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                          </vsud-button>
                        </div>
                        <div class="col-1 pe-6" v-if="index == 0">
                          <vsud-button
                            v-on:click="insertNewRow"
                            class="
                              my-4
                              mb-2
                              plus-button
                              hours-inputs
                              icon-button
                            "
                            variant="gradient"
                            color="success"
                            full-width
                            ><div class="icon-translate">
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 0C5.50908 0 5.11117 0.397907 5.11117 0.888833V5.11117H0.888833C0.397907 5.11117 0 5.50908 0 6C0 6.49093 0.397907 6.88883 0.888833 6.88883H5.11117V11.1112C5.11117 11.6021 5.50908 12 6 12C6.49093 12 6.88883 11.6021 6.88883 11.1112V6.88883H11.1112C11.6021 6.88883 12 6.49093 12 6C12 5.50908 11.6021 5.11117 11.1112 5.11117H6.88883V0.888833C6.88883 0.397907 6.49093 0 6 0Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                          </vsud-button>
                        </div>
                      </div>
                      <div class="row mt-3 flex-nowrap">
                        <div
                          class="
                            col-3
                            d-flex
                            align-items-center
                            project-name-inputs
                          "
                        >
                          <div>
                            Total Hours Booked This Week: {{ totalHoursWeek }}
                          </div>
                        </div>
                        <div
                          class="
                            col-3
                            d-flex
                            align-items-center
                            dropdown-inputs
                          "
                        >
                          <div></div>
                        </div>

                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="text"
                            disabled
                            class="
                              form-control form-control-default
                              hour-inputs
                              weekly-names
                            "
                            :value="totalHours[0]"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="text"
                            disabled
                            class="
                              form-control form-control-default
                              hour-inputs
                              weekly-names
                            "
                            :value="totalHours[1]"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="text"
                            disabled
                            class="
                              form-control form-control-default
                              hour-inputs
                              weekly-names
                            "
                            :value="totalHours[2]"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="text"
                            disabled
                            class="
                              form-control form-control-default
                              hour-inputs
                              weekly-names
                            "
                            :value="totalHours[3]"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="text"
                            disabled
                            class="
                              form-control form-control-default
                              hour-inputs
                              weekly-names
                            "
                            :value="totalHours[4]"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="text"
                            disabled
                            class="
                              form-control form-control-default
                              hour-inputs
                              weekly-names
                            "
                            :value="totalHours[5]"
                          />
                        </div>
                        <div class="col d-flex align-items-center mt-3">
                          <input
                            type="text"
                            disabled
                            class="
                              form-control form-control-default
                              hour-inputs
                              weekly-names
                            "
                            :value="totalHours[6]"
                          />
                        </div>
                        <div class="col-1"></div>
                      </div>
                      <div class="row justify-content-end custom-footer">
                        <div
                          class="col-sm-2 col-12 text-center timesheet-button"
                        >
                          <vsud-button
                            v-on:click="onSignin('draft')"
                            class="my-4 mb-2 accent-bg"
                            full-width
                            >Save as Draft
                          </vsud-button>
                        </div>
                        <div
                          class="col-sm-2 col-12 text-center timesheet-button"
                        >
                          <vsud-button
                            class="my-4 mb-2"
                            full-width
                            data-bs-toggle="modal"
                            data-bs-target="#submitModal"
                            >Submit
                          </vsud-button>
                        </div>
                      </div>
                    </div> -->

                    <div
                      class="
                        row
                        mt-n4
                        flex-wrap
                        justify-content-lg-end
                        justify-content-sm-start
                        justify-content-md-start
                        justify-content-start
                        custom-footer
                        cs_footer
                      "
                    >
                      <!-- <div class="col-2 d-flex" style="white-space: normal;">
                        <vsud-button
                          class="my-4 mb-2"
                          full-width
                          data-bs-toggle="modal"
                          data-bs-target="#addProjectModal"
                          >Add Project
                        </vsud-button>
                      </div> -->
                      <div class="col-8">
                        <div
                          class="
                            row
                            justify-content-lg-end
                            justify-content-sm-start
                            justify-content-md-start
                            justify-content-start
                            align-with-add-project
                          "
                        >
                          <div
                            class="col-sm-2 col-12 text-center timesheet-button"
                            v-if="isDraft"
                          >
                            <vsud-button
                              v-on:click="fetchPreviousTimesheet"
                              class="my-4 mb-2 full-width"
                              full-width
                              >{{ $t('copy_previous_week') }}
                            </vsud-button>
                          </div>
                          <div
                            class="
                              col-sm-2 col-12
                              text-center
                              timesheet-button
                            "
                          >
                            <vsud-button
                              v-on:click="onSignin('draft')"
                              class="my-4 mb-2 accent-bg"
                              full-width
                              v-if="isDraft"
                              >{{ $t('save_as_draft') }}
                            </vsud-button>
                          </div>
                          <div
                            class="
                              col-sm-2 col-12
                              text-center
                              timesheet-button
                            "
                            :class="
                              isDraft
                                ? 'withdraw-disabled'
                                : isWithDrawable
                                ? 'withdraw-enabled'
                                : 'withdraw-disabled'
                            "
                          >
                            <vsud-button
                              class="my-4 mb-2"
                              full-width
                              data-bs-toggle="modal"
                              data-bs-target="#withdrawModal"
                            >
                            {{ $t('withdraw') }}
                            </vsud-button>
                          </div>
                          <div
                            class="
                              col-sm-2 col-12
                              text-center
                              timesheet-button
                            "
                          >
                            <vsud-button
                              class="my-4 mb-2"
                              :class="isDraft ? 'accent-orange' : 'accent-bg'"
                              full-width
                              data-bs-toggle="modal"
                              @click="showAlreadySubmmited"
                              :data-bs-target="isDraft ? '#submitModal' : '#'"
                            >
                              <span v-if="isDraft">{{ $t('submit') }}</span>
                              <span v-else>{{ $t('submitted') }}</span>
                            </vsud-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import axios from "axios";
import { ref } from "vue";
import "vue-select/dist/vue-select.css";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "AddTimesheet",
  components: {
    VsudButton,
  },
  data() {
    return {
      task: "",
      one: 0,
      two: 0,
      three: 0,
      errorMessage: "",
      four: 0,
      five: 0,
      six: 0,
      seven: 0,
      projects: [],
      backupProjects: {},
      bpActivities: [],
      projectSelectedActivitiesList: [],
      weekHoursSettings: 0,
      dailyHoursSettings: 0,
      fromTimeSettings: "",
      toTimeSettings: "",
      workingDaySettings: [],
      workHours: [
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
        {
          from: "",
          to: "",
        },
      ],
      bookedHours: [],

      selectedProjects: [],
      newSelectedProject: "",
      newSelectedActivity: "",
      newActivityId: "",
      newProjectId: "",
      getModalActivities: [],
      windowWidth: 1300,
      isDraft: true,
      projectsAndActivities: {},
      allProjects: [],
      timesheet: [],
      isWithDrawable: false,
      projectModalError:false,
      activityModalError:false,
    };
  },
  setup() {
    const date = ref();
    return {
      date,
    };
  },
  created() {
    var _isUserLoggedIn = localStorage.isUserLoggedIn;
    if (_isUserLoggedIn === undefined) {
      localStorage.isUserLoggedIn = "no";
      _isUserLoggedIn = localStorage.isUserLoggedIn;
    }
    if (_isUserLoggedIn === "no") {
      this.$router.push({
        path: `/sign-in`,
      });
      return;
    }

    console.log(this.$route.params);
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");

    this.projects.forEach((project) => {
      project.activities.forEach((activity) => {
        activity.isSelected = false;
      });
    });
  },
  computed: {
    // projectsAndActivities() {
    //   let cont = {}
    //   this.bookedHours.forEach(bookedHour => {
    //     if(bookedHour.projectName in cont){
    //       cont[bookedHour.projectName].push(bookedHour)
    //     }
    //     else{
    //       cont[bookedHour.projectName] = []
    //       cont[bookedHour.projectName].push(bookedHour)
    //     }
    //   })
    //   return cont
    // },

    currentWeek() {
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const sunDiff = currentDate.getDate() - currentDay + 1;
      const satDiff = currentDate.getDate() + 6 - currentDay + 1;

      const d1 = new Date(
        new Date(currentDate.setDate(sunDiff)).setHours(0, 0, 0, 0)
      );
      const d2 = new Date(
        new Date(currentDate.setDate(satDiff)).setHours(0, 0, 0, 0)
      );

      if (d1.getDate() > d2.getDate()) {
        d2.setMonth(d2.getMonth() + 1);
      }

      return [d1, d2];
    },
    totalHours() {
      // let nBH = [];
      // this.selectedProjects.forEach(project => {
      //   project.activities.forEach(activity => {
      //     nBH.push({
      //       projectName: project.projectName,
      //       projectId: project.projectId,
      //       activityName: activity.activityName,
      //       activityId: activity.activityId,
      //       hours:activity.hours,
      //     })
      //     console.log("H",activity.hours)
      //   })
      // })

      let hrs = this.bookedHours;

      const hours = hrs.map((b) => b.hours);
      const sum = [];
      hours.forEach((h) => {
        h.forEach((hi, index) => {
          if (typeof sum[index] === "undefined") {
            sum[index] = hi;
          } else {
            sum[index] += hi;
          }
        });
      });
      return sum;
    },
    totalHoursWeek() {
      return this.totalHours.reduce((a, b) => a + b, 0);
    },
  },
  beforeMount() {
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    this.$store.state.isRTL = false;
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      console.log("H", this.windowWidth);
    });

    this.date = Array(2);
    this.date[0] = this.currentWeek[0];
    this.date[1] = this.currentWeek[1];
    if (this.$route.params.id) {
      console.log("timesheet/id");
    } else {
      this.fetchTimesheet();
    }
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    // this.$store.state.isRTL = false;
    axios
      .get(`${process.env.VUE_APP_BASE_URL}/settings`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          const settings = res.data.payload.settings;
          this.workingDaySettings = settings.workingDays;
          this.workHours[0].from = this.workingDaySettings.includes("Mon")
            ? settings.fromTime
            : "";
          this.workHours[1].from = this.workingDaySettings.includes("Tue")
            ? settings.fromTime
            : "";
          this.workHours[2].from = this.workingDaySettings.includes("Wed")
            ? settings.fromTime
            : "";
          this.workHours[3].from = this.workingDaySettings.includes("Thu")
            ? settings.fromTime
            : "";
          this.workHours[4].from = this.workingDaySettings.includes("Fri")
            ? settings.fromTime
            : "";
          this.workHours[5].from = this.workingDaySettings.includes("Sat")
            ? settings.fromTime
            : "";
          this.workHours[6].from = this.workingDaySettings.includes("Sun")
            ? settings.fromTime
            : "";
          this.fromTimeSettings = settings.fromTime;
          this.workHours[0].to = this.workingDaySettings.includes("Mon")
            ? settings.toTime
            : "";
          this.workHours[1].to = this.workingDaySettings.includes("Tue")
            ? settings.toTime
            : "";
          this.workHours[2].to = this.workingDaySettings.includes("Wed")
            ? settings.toTime
            : "";
          this.workHours[3].to = this.workingDaySettings.includes("Thu")
            ? settings.toTime
            : "";
          this.workHours[4].to = this.workingDaySettings.includes("Fri")
            ? settings.toTime
            : "";
          this.workHours[5].to = this.workingDaySettings.includes("Sat")
            ? settings.toTime
            : "";
          this.workHours[6].to = this.workingDaySettings.includes("Sun")
            ? settings.toTime
            : "";
          this.toTimeSettings = settings.toTime;
          this.weekHoursSettings = Number.parseInt(settings.weeklyHours);
          this.dailyHoursSettings = Number.parseInt(settings.dailyHours);
        }
      });
    axios
      .get(`${process.env.VUE_APP_BASE_URL}/project/user`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data) {
          if (res.data && res.data.payload && res.data.payload.projects) {
            this.projects = res.data.payload.projects;

            this.allProjects = res.data.payload.projects;

            this.projects = this.projects.filter(
              (project) => project.projectVisibility === true
            );

            this.projects.forEach((project) => {
project.activities=project.activities.sort((x, y) =>
        x.activityName.trim().toLowerCase() < y.activityName.trim().toLowerCase() ? -1 : 1
      );
              project.activities.forEach((activity) => {
                activity.isSelected = false;
              });
            });
this.projects=this.projects.sort((x, y) =>
        x.projectName.toLowerCase() < y.projectName.toLowerCase() ? -1 : 1
      );

            // console.log(this.projects)
          }
        }
      });
    if (this.$route.params.id) {
      this.resetAllSelectedActivities();
      this.pushProjectsAndActivities(this.bookedHours);

      axios
        .get(
          `${process.env.VUE_APP_BASE_URL}/timesheet/id/${this.$route.params.id}`,
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data) {
            if (res.data) {
              if (res.data && res.data.payload && res.data.payload.timesheet) {
                this.date = Array(2);
                const thisDate = new Date(res.data.payload.timesheet.startDate);
                const startDate = new Date(
                  thisDate.getTime() + thisDate.getTimezoneOffset() * 60000
                );
                this.date[0] = startDate;
                this.date[1] = new Date(
                  res.data.payload.timesheet.startDate
                ).setDate(startDate.getDate() + 6);
                this.timesheet = res.data.payload.timesheet;
                this.bookedHours = res.data.payload.timesheet.bookedHours;

                this.pushProjectsAndActivities(this.bookedHours);
                this.setBackupProjects();

                this.isDraft = res.data.payload.timesheet.isDraft;

                // if (!this.isDraft) {
                //   this.isWithDrawableCheck();
                // }

                // this.projects.forEach(project => {
                //   project.activities.forEach(activity =>{
                //     console.log("Activity Id", activity._id);
                //   })
                // })

                // this.bookedHours.forEach(bh => {
                //   console.log("Bh Activity Id", bh.activityId)
                // })

                // this.bookedHours.forEach(bookedHour => {
                //   this.projects.some(project => {
                //     if(bookedHour.projectId === project._id){
                //       console.log("P-id",bookedHour.projectId, project._id)
                //       project.activities.some(activity => {
                //       if(activity._id === bookedHour.activityId){
                //         console.log("A-id",activity._id, bookedHour.activityId)
                //         activity.isSelected = true
                //         return true
                //       }
                //     })
                //       return true
                //     }
                //   })
                // })

                this.workHours = res.data.payload.timesheet.workHours;
                this.$forceUpdate();
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  beforeUnmount() {
    body.classList.add("bg-gray-100");
  },

  watch: {
    isDraft(_new, _old) {
      console.log(_old);
      if (_new === true) {
        this.isWithDrawableCheck();
      } else {
        this.isWithDrawableCheck();
      }
    },
  },

  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    isWithDrawableCheck() {
      var wDrawable = false;
      const currentDate = new Date();
      if (this.date === undefined) {
        this.isWithDrawable = false;
      }
      const endDate = new Date(this.date[0]).getDate() + 365;
      console.log("cd", currentDate);
      const withDrawableDate = new Date(
        new Date(this.date[0]).setDate(endDate)
      );
      console.log("wd", withDrawableDate);
      if (currentDate <= withDrawableDate) {
        wDrawable = true;
        console.log("withdrawable true");
      } else {
        wDrawable = false;
        console.log("withdrawable false");
      }
      this.isWithDrawable = wDrawable;
    },

    withdraw() {
      axios
        .put(
          `${
            process.env.VUE_APP_BASE_URL
          }/timesheet/${this.date[0].getDate()}-${
            this.date[0].getMonth() + 1
          }-${this.date[0].getFullYear()}`,
          this.timesheet,
          {
            withCredentials: true,
          }
        )
        .then(() => {
          window.location.reload();
          return true;
        })
        .catch(() => {
          const toastLiveExample = document.getElementById("liveToast");
          const toast = new window.bootstrap.Toast(toastLiveExample);
          this.errorMessage = this.$t('timesheet_withdrawn');
          toast.show();
          return true;
        });
    },

    pushProjectsAndActivities(bookedHours) {
      this.projectsAndActivities = {};
      bookedHours.forEach((bookedHour) => {
        if (bookedHour.projectName in this.projectsAndActivities) {
          this.projectsAndActivities[bookedHour.projectName].push(bookedHour);
        } else {
          this.projectsAndActivities[bookedHour.projectName] = [];
          this.projectsAndActivities[bookedHour.projectName].push(bookedHour);
        }
      });
    },

    resetAllSelectedActivities() {
      this.backupProjects = {};
      this.projects.forEach((project) => {
        project.activities.forEach((activity) => {
          activity.isSelected = false;
        });
      });
    },

    setBackupProjects() {
      this.backupProjects = {};
      this.checkForDuplicateProjects();
    },

    showAlreadySubmmited() {
      if (!this.isDraft) {
        const toastLiveExample = document.getElementById("liveToast");
        const toast = new window.bootstrap.Toast(toastLiveExample);
        this.errorMessage = this.$t('timesheet_already_submitted');
        toast.show();
        return;
      }
    },

    checkForDuplicateProjects() {
      this.bookedHours.forEach((bookedHour) => {
        this.projects.some((project) => {
          if (bookedHour.projectId === project._id) {
            project.activities.some((activity) => {
              if (activity._id === bookedHour.activityId) {
                activity.isSelected = true;
                this.backupProjects[activity._id] = activity._id;
                return true;
              }
            });
            return true;
          }
        });
      });
    },

    setProjectName(event, item) {
      item.projectName = event.projectName;
      item.projectId = event._id;
    },
    setActivityName(event, item) {
      item.activityName = event.activityName;
      item.activityId = event._id;
    },

    setSelectedProject(event, item) {
      console.log("abc", event, item);
      
      this.projectModalError = false;
      this.newSelectedProject = event.projectName;
      this.newProjectId = event._id;

      console.log("NewProjectId", this.newProjectId);

      this.projects.forEach((project) => {
        if (project.projectName === this.newSelectedProject) {
          console.log("Heyy");
          this.getModalActivities = project.activities;
          return;
        }
      });
    },

    delProject(item, index) {
      console.log("index", index);

      for (let key in this.projectsAndActivities) {
        console.log("k", key);
        console.log(this.projectsAndActivities[key]);
      }
      this.projectsAndActivities[item.projectName].splice(index, 1);
      this.bookedHours.forEach((bookedHour, ind) => {
        if (bookedHour.activityId === item.activityId) {
          this.bookedHours.splice(ind, 1);
        }
      });

      if (this.projectsAndActivities[item.projectName].length === 0) {
        delete this.projectsAndActivities[item.projectName];
      }

      let _id = this.backupProjects[item.activityId];
      this.projects.forEach((p) => {
        p.activities.forEach((act) => {
          if (act._id === _id) {
            act.isSelected = false;
            delete this.backupProjects[_id];
            console.log("After delete", this.backupProjects);
            return;
          }
        });
      });
    },

    setNewProject() {
      const toastLiveExample = document.getElementById("liveToast");
      const toast = new window.bootstrap.Toast(toastLiveExample);

      if (this.newSelectedProject === "") {
        this.projectModalError = true;
      } else if (this.newProjectId === "") {
        this.errorMessage = this.$t('error_occured');
        toast.show();
      }
      else if (this.newSelectedActivity === "") {
        this.activityModalError = true;
      } else if (this.newActivityId === "") {
        this.errorMessage = this.$t('error_occured');
        toast.show();
      } else {
        this.projectModalError = false;
        this.activityModalError = false;
        this.bookedHours.push({
          projectName: this.newSelectedProject,
          activityName: this.newSelectedActivity,
          activityId: this.newActivityId,
          projectId: this.newProjectId,
          hours: [0, 0, 0, 0, 0, 0, 0],
        });

        let cont = this.projectsAndActivities;

        if (this.newSelectedProject in cont) {
          cont[this.newSelectedProject].push({
            projectName: this.newSelectedProject,
            activityName: this.newSelectedActivity,
            activityId: this.newActivityId,
            projectId: this.newProjectId,
            hours: [0, 0, 0, 0, 0, 0, 0],
          });
        } else {
          cont[this.newSelectedProject] = [];
          cont[this.newSelectedProject].push({
            projectName: this.newSelectedProject,
            activityName: this.newSelectedActivity,
            activityId: this.newActivityId,
            projectId: this.newProjectId,
            hours: [0, 0, 0, 0, 0, 0, 0],
          });
        }

        this.projects.forEach((project) => {
          if (project.projectName === this.newSelectedProject) {
            project.activities.forEach((activity, ind) => {
              if (activity.activityName === this.newSelectedActivity) {
                project.activities[ind].isSelected = true;
                this.backupProjects[activity._id] = activity._id;
                console.log("BackUp", this.backupProjects);
                return;
              }
            });
          }
        });


        var cl = document.getElementById("closeAddProjectModal");
        cl.click();
        
      this.resetAddProjectDialog();
      }

    },

    setSelectedActivity(event, item) {
      console.log("def", event, item);
      
      this.activityModalError = false;
      this.newSelectedActivity = event.activityName;
      this.newActivityId = event._id;
    },

    resetAddProjectDialog() {
      this.projectModalError = false;
      this.activityModalError = false;
      this.newSelectedProject = "";
      this.newSelectedActivity = "";
      console.log("Reset", this.newSelectedProject, this.newSelectedActivity);
    },

    setNewActivity(event, item) {
      console.log(event, item);
      item.activities.push({
        activityName: "",
        activityId: "",
        hours: [0, 0, 0, 0, 0, 0, 0],
      });
    },

    format(dates) {
      return `${dates[0].getDate()}/${
        dates[0].getMonth() + 1
      }/${dates[0].getFullYear()} - ${dates[1].getDate()}/${
        dates[1].getMonth() + 1
      }/${dates[1].getFullYear()}`;
    },

    deselectedActivity() {
      console.log("hi");
    },

    selectedActivity(selectedItem, index, item) {
      console.log(selectedItem, index, item);
      //this.setProjectName(selectedItem, item)

      // if(this.projectSelectedActivitiesList[index] === undefined){
      //   this.projectSelectedActivitiesList[index] = selectedItem._id
      //   console.log("no item", this.projectSelectedActivitiesList)
      // }
      // else{
      //   this.projects.forEach(project => {
      //     project.activities.forEach(item => {
      //     if(item._id === this.projectSelectedActivitiesList[index]){
      //       item.isSelected = false
      //       this.projectSelectedActivitiesList[index] = selectedItem._id
      //       console.log(this.projectSelectedActivitiesList[index])
      //     }
      //   })
      //   })
      // }

      // const arraySizeTest = index + 1

      // const selectedItemsSize = this.projectSelectedActivitiesList.length

      // if(arraySizeTest > selectedItemsSize){
      //   this.projectSelectedActivitiesList.push(selectedItem._id)
      // }
      // else {
      //       this.projects.forEach(project => {
      //       project.activities.forEach(item => {
      //        if(item._id === this.projectSelectedActivitiesList[index]){
      //        item.isSelected = false
      //       this.projectSelectedActivitiesList[index] = selectedItem._id
      //     }
      //   })
      //   })
      // }
      // selectedItem.isSelected = true
    },

    changedItem() {
      console.log("HIe");
    },

    exitPage() {
      this.isDraft = true;
      this.$store.state.showSidenav = true;
      this.$store.state.showNavbar = true;
      window.history.back();
    },

    validateHours(obj, index, j) {
      let value = 0;
      value = obj.target.value > 24 ? 24 : obj.target.value;
      value = value < 0 ? 0 : value;
      this.bookedHours[index].hours[j] = value;
      obj.target.value = value;
    },

    validateNewHours(obj, item, index, j) {
      console.log("item", item, index);
      let value = 0;
      value = obj.target.value > 24 ? 24 : obj.target.value >> 0;
      value = value < 0 ? 0 : value;
      console.log("Project ob", this.projectsAndActivities[item.projectName][index])
      this.projectsAndActivities[item.projectName][index].hours[j] = value;
      this.bookedHours.some((bookedHour) => {
        if (bookedHour.activityId === item.activityId) {
          bookedHour.hours[j] = value;
          console.log("Project bh", bookedHour.activityId, item.activityId)
          return true;
        }
      });
      obj.target.value = value;
    },

    activateTooltips() {
      setTimeout(() => {
        const tooltipTriggerList = [].slice.call(
          document.querySelectorAll('[data-bs-toggle="tooltip"]')
        );
        console.log(tooltipTriggerList);
        tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
      }, 0);
    },
    onSignin(isDraft) {
      let isValid = true;
      this.bookedHours.forEach((bookedHour) => {
        this.allProjects.some((project) => {
          if (
            bookedHour.projectId === project._id &&
            !project.projectVisibility
          ) {
            isValid = false;
            console.log("isValid", isValid);
            const toastLiveExample = document.getElementById("liveToast");
            const toast = new window.bootstrap.Toast(toastLiveExample);
            this.errorMessage = `The project ${project.projectName} has been disabled \n Please remove it to submit`;
            toast.show();
            return true;
          }
        });
      });

      console.log("IsValid2", isValid);

      if (!isValid) {
        return;
      }


      let allProjectsValid = true
      this.bookedHours.some((bookedHour) => {
        let isProjectPresent = false
        this.projects.some((project) => {
          if(project._id === bookedHour.projectId){
            isProjectPresent = true
            return true;
          }
        })
        if(!isProjectPresent){
          allProjectsValid = false;
          const toastLiveExample = document.getElementById("liveToast");
          const toast = new window.bootstrap.Toast(toastLiveExample);
          this.errorMessage = `The project ${bookedHour.projectName} is not assigned to you \n Please remove it to proceed`;
          toast.show();
          return true;
        }
      })

      if (!allProjectsValid) {
        return;
      }

      if (this.bookedHours.length === 0) {
        const toastLiveExample = document.getElementById("liveToast");
        const toast = new window.bootstrap.Toast(toastLiveExample);
        this.errorMessage = this.$t('atleast_project_error');
        toast.show();
        return;
      }

      if (isDraft) {
        if (
          isDraft !== "draft" &&
          this.totalHoursWeek < this.weekHoursSettings
        ) {
          const toastLiveExample = document.getElementById("liveToast");
          const toast = new window.bootstrap.Toast(toastLiveExample);
          this.errorMessage =
          this.$t('weekly_hours_error'); + this.weekHoursSettings;
          toast.show();
          return;
        }

        let isEmptyProject = false;
        let isEmptyActivity = false;
        let isThereOneEmptyProject = false;
        let isThereOneEmptyActivity = false;
        this.bookedHours.forEach((bookedHour) => {
          console.log("bh", bookedHour);
          if (
            !isThereOneEmptyProject &&
            (bookedHour.projectName === "" || bookedHour.projectName === null)
          ) {
            isThereOneEmptyProject = true;
            isEmptyProject = true;
          } else {
            isEmptyProject = false;
          }
          if (
            !isThereOneEmptyActivity &&
            (bookedHour.activityName === "" || bookedHour.activityName === null)
          ) {
            isThereOneEmptyActivity = true;
            isEmptyActivity = true;
          } else {
            isEmptyActivity = false;
          }
        });
        if (isEmptyProject || isEmptyActivity) {
          console.log(isEmptyProject, isEmptyActivity);
          const toastLiveExample = document.getElementById("liveToast");
          const toast = new window.bootstrap.Toast(toastLiveExample);

          if (isEmptyProject) {
            this.errorMessage = this.$t('project_empty_error');
          } else if (isEmptyActivity) {
            this.errorMessage = this.$t('activity_empty_error');
          }
          toast.show();
          return;
        }

        if(isDraft !== "draft"){

        let flag = true;
        if (
          this.totalHours[0] < this.dailyHoursSettings &&
          this.workingDaySettings.includes("Mon")
        )
          flag = false;
        if (
          this.totalHours[1] < this.dailyHoursSettings &&
          this.workingDaySettings.includes("Tue")
        )
          flag = false;
        if (
          this.totalHours[2] < this.dailyHoursSettings &&
          this.workingDaySettings.includes("Wed")
        )
          flag = false;
        if (
          this.totalHours[3] < this.dailyHoursSettings &&
          this.workingDaySettings.includes("Thu")
        )
          flag = false;
        if (
          this.totalHours[4] < this.dailyHoursSettings &&
          this.workingDaySettings.includes("Fri")
        )
          flag = false;
        if (
          this.totalHours[5] < this.dailyHoursSettings &&
          this.workingDaySettings.includes("Sat")
        )
          flag = false;
        if (
          this.totalHours[6] < this.dailyHoursSettings &&
          this.workingDaySettings.includes("Sun")
        )
          flag = false;
        if (!flag) {
          const toastLiveExample = document.getElementById("liveToast");
          const toast = new window.bootstrap.Toast(toastLiveExample);
          this.errorMessage =
          this.$t('daily_hours_error') +
            this.dailyHoursSettings +
            " Hours";
          toast.show();
          return;
        }
      }

      let isValueGreaterThanDay = false;
      if(this.totalHours[0] > 24){
        isValueGreaterThanDay = true;
      }
      else if(this.totalHours[1] > 24){
        isValueGreaterThanDay = true;
      }
      else if(this.totalHours[2] > 24){
        isValueGreaterThanDay = true;
      }
      else if(this.totalHours[3] > 24){
        isValueGreaterThanDay = true;
      }
      else if(this.totalHours[4] > 24){
        isValueGreaterThanDay = true;
      }
      else if(this.totalHours[5] > 24){
        isValueGreaterThanDay = true;
      }
      else if(this.totalHours[6] > 24){
        isValueGreaterThanDay = true;
      }

      if(isValueGreaterThanDay){
        const toastLiveExample = document.getElementById("liveToast");
          const toast = new window.bootstrap.Toast(toastLiveExample);
          this.errorMessage =
          this.$t('daily_24hours_error');
          toast.show();
          return;
      }



        const currentUtcDate = new Date(
          this.date[0].getTime() - this.date[0].getTimezoneOffset() * 60000
        );
        axios
          .post(
            `${process.env.VUE_APP_BASE_URL}/timesheet`,
            {
              startDate: currentUtcDate,
              startDateString: `${this.date[0].getDate()}-${
                this.date[0].getMonth() + 1
              }-${this.date[0].getFullYear()}`,
              isDraft: isDraft === "draft",
              bookedHours: this.bookedHours,
              workHours: this.workHours,
            },
            { withCredentials: true }
          )
          .then(() => {
            this.$router.push(`/overview`);
          })
          .catch((error) => {
            const toastLiveExample = document.getElementById("liveToast");
            const toast = new window.bootstrap.Toast(toastLiveExample);
            if (error.response.data && error.response.data.message)
              this.errorMessage = error.response.data.message;
            else this.errorMessage = this.$t('submission_failed');
            toast.show();
          });
      }
    },

    insertNewRow() {
      console.log(this.workHours);
      this.bookedHours.push({
        projectName: "",
        activityName: "",
        activityId: "",
        projectId: "",
        hours: [0, 0, 0, 0, 0, 0, 0],
      });
    },

    addNewActivity(item) {
      item.activities.push({
        activityName: "",
        activityId: "",
        hours: [0, 0, 0, 0, 0, 0, 0],
      });
    },

    addNewProject() {
      this.selectedProjects.push({
        projectName: "",
        projectId: "",
        activities: [],
      });
    },

    deleteNewProject(index) {
      this.selectedProjects.splice(index, 1);
    },

    deleteRow(index) {
      this.bookedHours.splice(index, 1);
      // let itemDeleted = false
      // this.projects.forEach(project => {
      //   project.activities.forEach(activity => {
      //      if(!itemDeleted && activity._id == this.projectSelectedActivitiesList[index]){
      //        activity.isSelected = false
      //        console.log(index)
      //        this.projectSelectedActivitiesList.splice(index,1)
      //        itemDeleted = true
      //        return
      //      }
      //   })
      // })
    },

    deleteNewRow(item, index) {
      item.activities.splice(index, 1);
    },
    fetchTimesheet() {
      this.resetAllSelectedActivities();

      this.bookedHours = [];
      this.pushProjectsAndActivities(this.bookedHours);
      this.isDraft = true;
      if (this.date && this.date[0]) {
        axios
          .get(
            `${
              process.env.VUE_APP_BASE_URL
            }/timesheet/${this.date[0].getDate()}-${
              this.date[0].getMonth() + 1
            }-${this.date[0].getFullYear()}`,
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            if (res.data) {
              if (res.data && res.data.payload && res.data.payload.timesheet) {
                this.timesheet = res.data.payload.timesheet;
                this.bookedHours = res.data.payload.timesheet.bookedHours;

                this.pushProjectsAndActivities(this.bookedHours);
                this.setBackupProjects();

                this.isDraft = res.data.payload.timesheet.isDraft;

                // if (!this.isDraft) {
                //   this.isWithDrawableCheck();
                // }

                this.workHours = res.data.payload.timesheet.workHours;
              } else {
                // this.bookedHours =
                //   {
                //     activityName: "",
                //     projectName: "",
                //     activityId: "",
                //     projectId: "",
                //     hours: [0, 0, 0, 0, 0, 0, 0],
                //   },
                // ];
                this.workHours[0].from = this.workingDaySettings.includes("Mon")
                  ? this.fromTimeSettings
                  : "";
                this.workHours[1].from = this.workingDaySettings.includes("Tue")
                  ? this.fromTimeSettings
                  : "";
                this.workHours[2].from = this.workingDaySettings.includes("Wed")
                  ? this.fromTimeSettings
                  : "";
                this.workHours[3].from = this.workingDaySettings.includes("Thu")
                  ? this.fromTimeSettings
                  : "";
                this.workHours[4].from = this.workingDaySettings.includes("Fri")
                  ? this.fromTimeSettings
                  : "";
                this.workHours[5].from = this.workingDaySettings.includes("Sat")
                  ? this.fromTimeSettings
                  : "";
                this.workHours[6].from = this.workingDaySettings.includes("Sun")
                  ? this.fromTimeSettings
                  : "";
                this.workHours[0].to = this.workingDaySettings.includes("Mon")
                  ? this.toTimeSettings
                  : "";
                this.workHours[1].to = this.workingDaySettings.includes("Tue")
                  ? this.toTimeSettings
                  : "";
                this.workHours[2].to = this.workingDaySettings.includes("Wed")
                  ? this.toTimeSettings
                  : "";
                this.workHours[3].to = this.workingDaySettings.includes("Thu")
                  ? this.toTimeSettings
                  : "";
                this.workHours[4].to = this.workingDaySettings.includes("Fri")
                  ? this.toTimeSettings
                  : "";
                this.workHours[5].to = this.workingDaySettings.includes("Sat")
                  ? this.toTimeSettings
                  : "";
                this.workHours[6].to = this.workingDaySettings.includes("Sun")
                  ? this.toTimeSettings
                  : "";
              }
            }
          })
          .catch((e) => {
            console.log(e);
            // console.log(this.isDraft)
            // this.isDraft = true;
          });
      }
    },
    fetchPreviousTimesheet() {
      //for test
      //this.resetAllSelectedActivities();
      //this.pushProjectsAndActivities(this.bookedHours);

      this.isDraft = true;
      if (this.date && this.date[0]) {
        const prevDeff = new Date(this.date[0]).getDate() - 7;
        const prevDate = new Date(new Date(this.date[0]).setDate(prevDeff));
        axios
          .get(
            `${process.env.VUE_APP_BASE_URL}/timesheet/${prevDate.getDate()}-${
              prevDate.getMonth() + 1
            }-${prevDate.getFullYear()}`,
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            if (res.data && res.data.payload && res.data.payload.timesheet) {
              this.timesheet = res.data.payload.timesheet;
              this.bookedHours = res.data.payload.timesheet.bookedHours;

              if (this.bookedHours.length === 0) {
                const toastLiveExample = document.getElementById("liveToast");
                const toast = new window.bootstrap.Toast(toastLiveExample);
                this.errorMessage =
                this.$t('projects_error');
                toast.show();
                return;
              }
              this.resetAllSelectedActivities();
              this.pushProjectsAndActivities(this.bookedHours);
              this.setBackupProjects();
              //this.isDraft = res.data.payload.timesheet.isDraft;
              this.isDraft = true;
              this.workHours = res.data.payload.timesheet.workHours;
            } else {
              const toastLiveExample = document.getElementById("liveToast");
              const toast = new window.bootstrap.Toast(toastLiveExample);
              this.errorMessage = this.$t('projects_error');
              toast.show();
              return;
            }
          })
          .catch((e) => {
            console.log("err", e.response);
          });
      }
    },
  },
};
</script>

<style scoped>
/* .highlight_bg{
  background: #ECEFF1 !important;
  width: 972px !important;
} */

.modal_error_cs{
  color:red;
  font-weight:500;
  font-size: smaller;
}
.weekly_calendar_img_style {
  width: 190px;
  height: 170px;
  margin-top: 20px;
}

.arrow_img_style {
  margin-left: 120px;
  margin-top: 4px;
}

.arrow_img_style_icon {
  width: 40px;
  height: 30px;
}

.arrow_text_style {
  width: 300px;
  position: relative;
  display: flex;
  margin-left: -70px;
}

.arrow_text_style_text {
  width: 200px;
  height: 28px;
  padding: 2px;
  background: white;
}

.arrow_shower {
  text-align: center;
  display: flex;
  justify-content: center;
}

.no_records_found {
  margin-top: 10px;
}

.warning_img_style {
  width: 48px;
  height: 48px;
}
.datepicker__input {
  width: 250px;
}

.close_btn_style {
  width: 32px !important;
  height: 32px !important;
  margin-top: 24px;
}

.close_btn_style_new {
  width: 32px !important;
  height: 32px !important;
  margin-bottom: 5px !important;
}

.form-control {
  /* height: 34px; */
  width: 92px !important;
  margin-bottom: 4px;
}

.table_style {
  position: relative;
  width: 100%;
  /* margin: 0 auto; */
}

.cs_tr_s {
  height: 80px !important;
}

.ts_footer {
  margin-top: 120px !important;
}

.text_md {
  padding-left: 2px !important;
}

.cs_tr_title {
  height: 60px !important;
}

.th_center {
  text-align: center;
}

.table_th_one_style {
  min-width: 250px;
  max-width: 250px;
  white-space: normal;
}

.table_th_one_style_two {
  min-width: 250px;
  max-width: 250px;
  white-space: normal;
}

.table_th_last_style {
  min-width: 100px;
  max-width: 100px;
  white-space: normal;
}

.table_tr_style {
  position: relative;
  background: #72758a;
}

.cs_th_style {
  padding-top: 0px;
}

.table thead th {
  padding-top: 2px;
  border-bottom: 0px;
  padding-right: 2px;
  padding-left: 2px;
  padding-bottom: 0px;
}

.table > tbody > tr > th {
  border: none;
}

.table thead tr th {
  vertical-align: middle;
}

.noborder {
  border: none;
}

.cw_cs_style {
  margin-left: 200px;
}

.cs_font_family {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #4e5d78;
}

.cs_title_font {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #ff814f;
}

.title_tr_cs {
  margin-top: 6px !important;
}

.cs_font_activity {
  font-style: normal;
  font-weight: 600;
  font-size: 13.5px;
  /* identical to box height */

  letter-spacing: 0.05em;

  color: #969eab;
}
</style>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.align-with-add-project{
  margin-left: 7px;
}

</style>

<style>
.table thead th {
  padding: 22px;
}

.week_name_style {
  min-width: 86px !important;
  max-width: 86px !important;
}

.hr_width {
  max-width: 1000px !important;
}

.vs__dropdown-toggle {
  height: 34px !important;
}
:root {
  --vs-dropdown-max-height: 120px;
}

div.tooltip-inner {
  max-width: 300px;
}

.icon-translate {
  transform: translate(-4px, 0);
}

.custom-window {
  overflow-x: auto;
  /* overflow-y: scroll; */
  padding-bottom: 10px;
  white-space: nowrap;
}

.modal-footer {
  border-top: 0px !important;
}
.btn-success:hover,
.btn.bg-gradient-success:hover {
  background-color: #ff814f;
  border-color: transparent !important;
}

.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #000;
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0 0 0 0.2rem transparent !important;
}

.cs_footer {
  max-width: 100%;
}

.custom-window .row .col {
  display: inline-block;
  float: none;
}

.full-width {
  width: 250px !important;
}

.zero-padding {
  padding: 0 !important;
}

.timesheet-button {
  min-width: 200px;
}

.accent-bg {
  background-color: #72758a !important;
}

.accent-orange {
  background-color: #ff814f !important;
}

.cs_width {
  width: 220px !important;
  height: 54px;
}

/* .plus-button {
  padding-right: 28px;
} */

button.icon-button {
  border-radius: 100% !important;
  padding: 0;
  position: relative;
  width: 40px !important;
  height: 40px !important;
}

button.icon-button div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hour-inputs {
  min-width: 92px !important;
  max-width: 93px !important;
  flex-basis: auto !important;
  flex-grow: 1 !important;
  padding: 0.5rem 0.75rem !important;
}

.time-inputs {
  min-width: 120px !important;
  max-width: 120px !important;

  background: #eceff1;
  flex-basis: auto !important;
  flex-grow: 1 !important;
  padding: 0.5rem 0.75rem !important;
}

.cs_inputs {
}

.dropdown-inputs {
  min-width: 390px !important;
  max-width: 390px !important;
  flex-basis: auto !important; /* default value */
  flex-grow: 1 !important;
}
.project-name-inputs {
  min-width: 280px !important;
  max-width: 280px !important;
  flex-basis: auto !important; /* default value */
  flex-grow: 1 !important;
}
.fron-time-inputs {
  min-width: 150px !important;
  max-width: 150px !important;
  background: #eceff1;
  flex-basis: auto !important; /* default value */
  flex-grow: 1 !important;
}
.weekly-names {
  outline: none;
  border: none !important;
}

.withdraw-disabled {
  display: none;
}

.withdraw-enabled {
  display: block;
}

.weekly-names:disabled {
  background-color: unset !important;
}
</style>
<style>
input[type="time"]:focus,
input[type="number"]:focus {
  box-shadow: 0 0 0 0.1em #ff814f;
  outline: 0 none;
}

.dp__input {
  height: 44px;
  border-radius: 4px;
  border-block: 3px 3px;
}
.dp__theme_light {
  --dp-primary-color: linear-gradient(310deg, #ff814f 0%, #ff814f 100%);
}
.dp__active_date {
  background: linear-gradient(310deg, #ff814f 0%, #ff814f 100%);
}

@media screen and (max-width: 480px) {
  body {
    overflow-y: scroll;
  }
}

.vs__dropdown-toggle {
  height: max-content !important;
}

.ts-status-draft div {
  /* width: 200px;
  padding: 5px;
  background-color: rgba(255, 129, 79, 0.2);
  border-radius: 30px;
  color: rgba(255, 129, 79, 1); */
}

.ts-status-submitted div {
  width: 200px;
  padding: 5px;
  background-color: rgba(229, 246, 239, 1);
  border-radius: 30px;
  color: rgba(56, 203, 137, 1);
}
</style>
