<template>
  <div class="position-fixed cs_end p-3 opacity-100" style="z-index: 11">
    <div
      id="liveToast"
      class="toast bg-warning opacity=100"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-header">
        <strong class="me-auto">{{ $t('error') }}</strong>
        <button
          type="button"
          class="btn-close bg-primary"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
      <div class="toast-body">
        {{ errorMessage }}
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <!--Here-->
      <div
        class="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">{{ $t('confirmation') }}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              {{ $t('delete_confirmation') }}?
              <br />
              {{ $t('deleted_undone') }}!
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
              {{ $t('no') }}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                @click="deleteUser(toDelete)"
              >
              {{ $t('yes') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--Close-->

      <div class="mx-auto col-12 flex-column">
        <div class="mt-4 card card-plain">
          <div class="card mb-4">
            <div class="card-header pb-0 p-3">
              <div class="row">
                <div class="col-6 d-flex align-items-center">
                  <h6 class="mb-0">{{ $t('timesheet_overview') }}</h6>
                </div>
                <div class="col-6 text-end">
                  <vsud-button @click="goToTimesheet">
                    <i class="fas fa-plus me-2"></i>
                    {{ $t('add_new_record') }}
                  </vsud-button>
                </div>
              </div>
            </div>
            <div class="card-body px-0 pt-0 pb-2 mt-2">
              <div class="table-responsive p-0">
                <table
                  class="table align-items-center justify-content-center mb-0"
                >
                  <thead>
                    <tr>
                      <th>{{ $t('week') }}</th>
                      <th class="text-center">{{ $t('monday') }}</th>
                      <th class="text-center">{{ $t('tuesday') }}</th>
                      <th class="text-center">{{ $t('wednesday') }}</th>
                      <th class="text-center">{{ $t('thursday') }}</th>
                      <th class="text-center">{{ $t('friday') }}</th>
                      <th class="text-center">{{ $t('saturday') }}</th>
                      <th class="text-center">{{ $t('sunday') }}</th>
                      <th class="text-center">{{ $t('total') }}</th>
                      <th class="text-center">{{ $t('status') }}</th>
                      <th class="text-center"></th>
                    </tr>
                  </thead>
                  <tbody v-if="projects.length === 0">
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="text-center">{{ $t('no_data_found') }}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                  <tbody v-if="projects.length > 0">
                    <tr
                      class="table-row"
                      v-for="project in projects"
                      v-bind:key="project._id"
                    >
                      <td class="text-center" @click="goToWeek(project._id)">
                        <div class="d-flex px-2">
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">
                              {{ getCurrentWeek(project.startDate) }}
                            </h6>
                          </div>
                        </div>
                      </td>
                      <td class="text-center" @click="goToWeek(project._id)">
                        {{ project.sumHours ? project.sumHours[0] : 0 }}
                      </td>
                      <td class="text-center" @click="goToWeek(project._id)">
                        {{ project.sumHours ? project.sumHours[1] : 0 }}
                      </td>
                      <td class="text-center" @click="goToWeek(project._id)">
                        {{ project.sumHours ? project.sumHours[2] : 0 }}
                      </td>
                      <td class="text-center" @click="goToWeek(project._id)">
                        {{ project.sumHours ? project.sumHours[3] : 0 }}
                      </td>
                      <td class="text-center" @click="goToWeek(project._id)">
                        {{ project.sumHours ? project.sumHours[4] : 0 }}
                      </td>
                      <td class="text-center" @click="goToWeek(project._id)">
                        {{ project.sumHours ? project.sumHours[5] : 0 }}
                      </td>
                      <td class="text-center" @click="goToWeek(project._id)">
                        {{ project.sumHours ? project.sumHours[6] : 0 }}
                      </td>
                      <td class="text-center" @click="goToWeek(project._id)">
                        {{ project.sumHours ? project.sumHours[7] : 0 }}
                      </td>
                      <td
                        :class="
                          project.isDraft
                            ? 'text-center ts-status-draft'
                            : 'text-center ts-status-submitted'
                        "
                        @click="goToWeek(project._id)"
                      >
                        <div>
                          {{ project.isDraft ? this.$t('draft') : this.$t('submitted') }}
                        </div>
                      </td>
                      <td class="text-center" @click="showOptions">
                        <div
                          v-if="true"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteModal"
                          @click="toDel(project.startDateString)"
                        >
                          <i
                            style="color: #ff814f"
                            class="far fa-trash-alt me-2"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VsudButton from "@/components/VsudButton.vue";
// import AddTimesheet from "./AddTimesheet.vue";

export default {
  name: "projects-table",
  components: {
    VsudButton,
    // AddTimesheet
  },
  data() {
    return {
      toDelete: "",
      projects: [],
    };
  },

  beforeMount() {
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
  },
  mounted() {
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
    axios
      .get(`${process.env.VUE_APP_BASE_URL}/timesheet`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data) {
          if (res.data && res.data.payload && res.data.payload.timesheets) {
            this.projects = res.data.payload.timesheets;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  created() {
    var _isUserLoggedIn = localStorage.isUserLoggedIn;
    if (_isUserLoggedIn === undefined) {
      localStorage.isUserLoggedIn = "no";
      _isUserLoggedIn = localStorage.isUserLoggedIn;
    }
    if (_isUserLoggedIn === "no") {
      this.$router.push({
        path: `/sign-in`,
      });
      return;
    }
  },

  methods: {
    showOptions() {},
    fetchData() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/timesheet`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data) {
            if (res.data && res.data.payload && res.data.payload.timesheets) {
              this.projects = res.data.payload.timesheets;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteUser(toDelete) {
      const toastLiveExample = document.getElementById("liveToast");
      const toast = new window.bootstrap.Toast(toastLiveExample);
      axios
        .delete(`${process.env.VUE_APP_BASE_URL}/timesheet/` + toDelete, {
          withCredentials: true,
        })
        .then(() => {
          this.errorMessage = this.$t('record_deleted_successfully');
          toast.show();
          this.fetchData();
        })
        .catch((error) => {
          this.errorMessage = this.$t('error_deleting_the_record');
          toast.show();
          console.log(error);
        });
    },

    toDel(startDate) {
      this.toDelete = startDate;
    },

    getCurrentWeek(startTime) {
      const startDay = new Date(startTime);
      const newStartDayText = startDay.getDate() + "/" + (startDay.getMonth() + 1) + "/" + startDay.getFullYear();
      const endDay = new Date(startDay.setDate(startDay.getDate() + 6));
      const newEndDayText = endDay.getDate() + "/" + (endDay.getMonth() + 1) + "/" + endDay.getFullYear();
      const week = `${newStartDayText} - ${newEndDayText}`;
      return week;
    },
    goToTimesheet() {
      this.$store.state.showSidenav = false;
      this.$router.push({
        path: `/timesheet`,
      });
    },
    goToWeek(id) {
      this.$store.state.showSidenav = false;
      this.$router.push({
        path: `/timesheet/${id}`,
      });
    },
  },
};
</script>

<style scoped>
.table-row {
  cursor: pointer;
}

.ts-status-draft div {
  width: 100%;
  padding: 5px;
  background-color: rgba(255, 129, 79, 0.2);
  border-radius: 30px;
  color: rgba(255, 129, 79, 1);
}

.ts-status-submitted div {
  width: 100%;
  padding: 5px;
  background-color: rgba(229, 246, 239, 1);
  border-radius: 30px;
  color: rgba(56, 203, 137, 1);
}

.cs_img {
  height: 16px;
  width: 16px;
}

.cs_end {
  right: 0;
  top: 0;
}
</style>